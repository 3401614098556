.author-container {
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #131827, $alpha: 0.9);
    color: white;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;

    .container{
        height: 100vh;
        padding: 10px;
    }
  
    .media-wrapper {
      i {
        width: 50px;
      }
      .paragraph {
        width: 160px;
      }
    }
  
    a {
      color: white;
  
      &:hover {
        color: $theme-color;
      }
    }

    b{
        font-weight: 600;
    }

    h1{
        font-size: 24px;
    }

    .mr-tan-message{
        padding-top: 90px;

        .image-container{
            position: relative;
            top: -40px;
            left: 0;

            .mr-tan-img{
                border: 2px solid #F1B148;
                border-radius: 25px;
                padding: 15px;
                position: relative;
                top: -10px;
                left: 0;
                width: fit-content;
            }

            .sidebar-qrvideo-container{
                position: absolute;
                width: 95px;
                height: 100px;
                background-color: white;
                border-radius: 10px;
                text-align: center;
                transition: all .2s ease-in-out;

                p{
                    color: #202c51;
                    font-weight: 500;
                    font-size: 10px;
                    text-align: center;
                    padding: 4px 0 0 0;
                    margin: 0;
                }

                .qr-code{
                    width: 65px;
                    padding: 0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .paragraph{
                padding-top: 5px;
            }
            .photo-credit{
                color: white;
                font-size: 12px;
                margin: 0;
                text-align: left;
                font-style: italic;
                padding-top: 5px;
            }
        }
    }

    .introduction-video-container{
        margin-bottom: 30px;

        .ytb-iframe-wrapper {
            iframe {
              height: 70vh;
              width: 90%;
            }
        }
        
        // .cards-container{
        //     position: relative;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        //     height: 100%;

        //     .cards-wrapper {
        //         display: flex;
        //         justify-content: center;
        //         gap: 2%;
        //     }
            
        //     .flip-card {
        //         background-color: transparent;
        //         perspective: 1000px;
        //         height: 100%;
        //         cursor: pointer;
        //         position: relative;
            
        //         .flip-card-inner {
        //           position: relative;
            
        //           text-align: center;
        //           transition: transform 0.8s;
        //           transform-style: preserve-3d;
        //           box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            
        //             .flip-card-front, .flip-card-back {
        //                 position: absolute;
        //                 width: 100%;
        //                 height: 100%;
                
        //                 -webkit-backface-visibility: hidden;
        //                 backface-visibility: hidden;
        //                 background-position: center;
        //                 background-size: cover;
        //                 -webkit-perspective: 0;
        //                 -webkit-transform: translate3d(0, 0, 0);
                
        //                 img {
        //                     width: 70%;
        //                     position: absolute;
        //                 }
                
        //                 img.type__0 {
        //                     top: 0;
        //                     right: 1%;
                
        //                     object-position: left top;
        //                     object-fit: contain;
        //                 }
                
        //                 img.type__1 {
        //                     right: 5%;
        //                     bottom: 0px;
        //                     object-position: bottom right;
        //                     object-fit: contain;
        //                 }

        //                 img.type__2 {
        //                     top: 0;
        //                     left: 7%;
        //                     object-position: left top;
        //                     object-fit: contain;
        //                 }

        //                 .imgback{
        //                     position: absolute;
        //                     top: 0;
        //                     width: 100%;
        //                     object-fit: cover;
        //                 }
        //             }
            
        //             .flip-card-front {
        //                 background-color: #202c51;
                
        //                 .food-section-wrapper {
        //                     padding: 20px;
        //                     display: flex;
        //                     flex-direction: column;
        //                     align-items: flex-start;
        //                     justify-content: space-between;
        //                     color: white;
        //                     z-index: 5;
                
        //                     .header {
        //                         font-size: 1.4rem;
        //                         font-family: $font_merriweather;
        //                         font-weight: 700;
        //                         text-align: start;
        //                     }
                
        //                     .small-text {
        //                         font-size: 0.8rem;
                    
        //                         text-align: start;
        //                         font-family: $font_barlow;
        //                         font-weight: 300;
                    
        //                         overflow: hidden;
        //                     }
        //                 }
                
        //                 .bottom-bar {
        //                     background-color: #F1B148;
        //                     display: flex;
        //                     justify-content: center;
        //                     align-items: center;
        //                     box-shadow: -4px 0 4px 2px rgba(80, 79, 79, 0.438);
                
        //                     span {
        //                         text-align: center;
        //                         color: black;
        //                         text-transform: uppercase;
        //                         font-family: $font_barlow;
        //                         font-weight: 700;
        //                     }
        //                 }
        //             }
            
        //             .flip-card-back {
        //                 background-color: #202c51;
        //                 transform: rotateY(180deg);
        //                 -webkit-transform: rotateY(180deg);
                
        //                 .quote-container {
        //                     border: 1px solid #f1b148;
        //                     background-color: #fbf7f5;
        //                     width: 120px;
        //                     color: #202c51;
        //                     font-size: 9px;
        //                     font-family: $font_merriweather;
        //                     font-size: 700;
        //                     position: absolute;
        //                     padding: 10px;
        //                     text-align: start;
        //                 }
                
        //                 .detail-section-wrapper {
        //                     padding: 10px;
                
        //                     @include media-lg {
        //                     padding: 20px;
        //                     }

        //                     //display: flex;
        //                     color: white;
        //                     //flex-direction: column;
        //                     overflow: hidden;
        //                     z-index: 5;

        //                     br {
        //                         content: " ";
        //                         display: block;
        //                         margin: 10px 0;
        //                         line-height: 10px;
        //                      }

        //                     .details {
        //                         text-align: center;
        //                         margin-top: 250px;

        //                         .header{
        //                             font-family: $font_barlow;
        //                             font-weight: 700;
        //                             white-space: pre-line;
        //                         }
                    
        //                         .text {
        //                             font-family: $font_barlow;
        //                             white-space: pre-line;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
            
        //         &.active {
        //             .flip-card-inner {
        //                 transform: rotateY(180deg);
        //                 -webkit-transform: rotateY(180deg);
        //             }
        //         }
            
        //         .overflow {
        //           height: 70vh;
        //           overflow: auto;
        //         }
            
        //         @include media-lg {
        //             .overflow {
        //                 overflow: visible;
        //             }
        //         }
        //     }
    
        //     .inactive {
        //         .border-left-animation {
        //           @extend .border-left-insert;
        //         }
        //     }
            
        //     .flip-card.active {
        //         .flip-card-front .food-section-wrapper {
        //             .border-left-animation {
        //                 position: relative;
        //                 display: block;
        //                 padding-left: 7px;
        //                 border-left: 1px solid $theme-color;
        //                 pointer-events: none;
        //             }
        //         }
        //     }
        // }

        // .giveaway-details{
        //     padding-top: 50px;
        //     width: 95%;

        //     .details-header{
        //         height: 50px;
        //         background-color: #202c51;
        //         padding: 5px 0 0 10px;
        //         align-content: center;
        //     }
        //     .details-body{
        //         background-color: #FBF7F5;
        //         background-image: url("../static/launch/sidebar/giveaway_bg.png");
        //         padding: 40px 30px 10px 30px;
                
        //         table{
        //             // margin-right: auto;
        //             // margin-left: auto;
        //             border-spacing: 10px;
        //             width: 80%;

        //             .table-data{
        //                 vertical-align: top;
        //                 padding-bottom: 30px;
        //                 padding-right: 15px;
        //             }

        //             p{
        //                 color: #202c51;
        //             }

        //             .header{
        //                 font-weight: 600;
        //                 font-size: 20px;
        //                 margin: 0;
        //                 padding-top: 2px;
        //                 padding-bottom: 15px;
        //             }

        //             .text{
        //                 font-weight: 400;
        //                 font-size: 15px;
        //                 margin: 0;
        //             }

        //             ul{
        //                 color: #202c51;
        //                 font-weight: 400;
        //                 font-size: 13px;
        //                 margin: 0;
        //                 padding: 5px 0 10px 15px;

        //             }

        //             span{
        //                 font-weight: 700;
        //                 color: #F1B148;
        //             }

        //             a{
        //                 color: #202c51;
        //                 transition: color 0.3s;
        //                 text-decoration: underline;
        //                 &:hover {
        //                   color: $theme-color;
        //                 }
        //             }
        //         }

        //         .giveaway-btn-container{
        //             text-align: center;
        //             height: 80px;
        //             .giveaway-btn{
        //                 background-color: #f1b148;
        //                 font-weight: 600;
        //                 color: $popup-color;
        //                 @include responsive-shadow;
                    
        //                 &:hover {
        //                     background-color: #e9c182;
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    .sticker-pack{

        .header{
            width: 100%;
            position: relative;
            z-index: 2;
        }

        .border-sticker{
            line-height: 0.5;
            text-align: center;

            span{
                display: inline-block;
                position: relative;
            }

            span::before, span::after{
                content: "";
                position: absolute;
                border: 1px solid #f1b148;
                top: 50%;
                width: 2000px;
            }

            span::before {
                right: 100%;
                margin-right: 15px;
            }

            span::after {
                left: 100%;
                margin-left: 15px;
            }
        }

        .sticker-details{
            padding-top: 50px;

            .sticker-gif{
                position: absolute;
                right: 10%;
            }

            .col-7{
                padding-top: 30px;
            }

            .sticker-download{
                padding-top: 20px;

                .sticker-qr-container-tap{
                    position: relative;
                    background-color: white;
                    border-radius: 10px;
                    text-align: center;
                    height: 110px;
                    width: 100px;
                    transition: all .2s ease-in-out;
                    z-index: 5;

                    p{
                        color: #202c51;
                        font-weight: 500;
                        font-size: 13px;
                        text-align: center;
                        padding: 4px 0 0 0;
                        margin: 0;
                    }

                    .qr-code{
                        height: 80px;
                    }
                }

                .sticker-qr-container{
                    position: relative;
                    background-color: white;
                    border-radius: 10px;
                    text-align: center;
                    height: 110px;
                    width: 100px;
                    transition: all .2s ease-in-out;
                    z-index: 5;

                    p{
                        color: #202c51;
                        font-weight: 500;
                        font-size: 11px;
                        text-align: center;
                        padding: 4px 0 0 0;
                        margin: 0;
                    }

                    .qr-code{
                        height: 70px;
                    }

                    &:hover {
                        transform: scale(2);
                    }
                }

                .col-6{
                    .row{
                        padding-bottom: 10px;
                    }
                }

                .paragraph{
                    font-family: $font_montserrat;
                    font-weight: 600;
                    font-size: 15px;
                }

                .app-name{
                    padding-top: 5px;
                    padding-left: 10px;
                }
            }
        }

        .btn-row{
            margin-top: 100px;
            margin-bottom: 50px;
            .back-top-button{
                position: absolute;
                bottom: 0;
                right: 5%;
            }
        }
    }
}

.pop-up-bg {
    @include full-screen;
    @include top-left;
    background-color: rgba($color: #131827, $alpha: 0.5);
}

.pop-up-container{
    width: 100vw;
    height: 100vh;

    .header{
        color: white;
        font-family: $font_barlow;
    }

    .gold-text{
        color: #F1B148;
    }

    .text{
        font-size: 20px;
        font-weight: 600;
        color: white;
    }

    .small-text{
        font-size: 15px;
        color: white;
        font-weight: 400;
        padding: 10px 0 0 0;
    }

    .medium-text{
        font-size: 16px;
        color: white;
        font-weight: 400;
        padding: 10px 0 0 0;
    }

    .pop-up{
        width: 550px;
        height: 574px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        overflow-x: hidden;

        .bg-img{
            position: fixed;
            width: 100%;
        }

        .btn-top-right {
            top: 4%;
        }

        .montserrat{
            font-family: $font_montserrat;
        }

        .header-container{
            text-align: center;
            .header{
                height: 89px;
                width: 475px;
                line-height: 89px;
                font-size: 27px;
            }
        }

        .popup-details-container{
            text-align: center;
            
            .details-header{
                height: 84px;
                width: inherit;
                color: #F1B148;
                line-height: 84px;
                font-family: $font_barlow;
                font-size: 28px;
                font-weight: 600;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .sticker-header{
                @extend .gold-text;
                font-size: 28px;
                font-family: $font_barlow;
                font-weight: 700;
                line-height: 34px;
                text-align: center;
                letter-spacing: 0.02em;
            }

            .sticker-gif{
                width: 300px;
                margin-bottom: 20px;
            }

            .sticker-download{
                position: relative;
                margin-top: 40px;
                margin-bottom: 30px;
                margin-left: auto;
                margin-right: auto;
                
                .sticker-qr-container-tap{
                    position: absolute;
                    right: 10%;
                    background-color: white;
                    border-radius: 10px;
                    text-align: center;
                    height: 140px;
                    width: 140px;
                    transition: all .2s ease-in-out;
                    z-index: 5;

                    p{
                        color: #202c51;
                        font-weight: 500;
                        font-size: 13px;
                        text-align: center;
                        padding: 7px 5px 0 5px;
                        margin: 0;
                    }

                    .qr-code{
                        height: 105px;
                    }
                }

                .sticker-qr-container{
                    position: absolute;
                    right: 10%;
                    background-color: white;
                    border-radius: 10px;
                    text-align: center;
                    height: 140px;
                    width: 140px;
                    transition: all .2s ease-in-out;
                    z-index: 5;

                    p{
                        color: #202c51;
                        font-weight: 500;
                        font-size: 13px;
                        text-align: center;
                        padding: 7px 5px 0 5px;
                        margin: 0;
                    }

                    .qr-code{
                        height: 105px;
                    }

                    &:hover {
                        transform: scale(2);
                    }
                }

                .available-text-container{
                    margin-top: 13px;
                    .row{
                        padding-bottom: 10px;
                    }
                }

                .paragraph{
                    font-family: $font_montserrat;
                    font-weight: 600;
                    font-size: 15px;
                    color: white;
                }

                .app-name{
                    padding-top: 5px;
                    padding-left: 10px;
                }
            }

            // .prize02-03-container{
            //     margin: 0;
            //     padding: 60px 0 0 20px;

            //     .prize-02-container{
            //         padding: 0 0 0 30px;
            //     }

            //     .prize-03-container{
            //         padding: 0 30px 0 0;
            //     }

            //     .prize-02, .prize-03{
            //         width: 170px;
            //         height: fit-content;
            //     }

            //     .prize-header{
            //         @extend .gold-text;
            //         font-size: 20px;
            //         font-family: $font_barlow;
            //         font-weight: 700;
            //         padding: 15px 20px 10px 20px;
            //     }

            //     .small-text{
            //         margin: 0 auto;
            //         padding: 0 5px 5px 5px;
            //         width: 178px;
            //         text-align: center;
            //     }
            // }

            .header{
                height: auto;
                font-size: 23px;
                padding: 30px 0 30px 0;

                span{
                    color: #F1B148;
                }
            }

            .instructions-bg{
                width: 90%;
                background-image: url("../static/launch/sidebar/instructions_bg.png");
                background-size: cover;
                padding: 15px;
                margin: 0;
                margin-left: auto;
                margin-right: auto;

                .medium-text{
                    color: #202c51;
                }

                .medium-large-text{
                    font-size: 17px;
                }
            }

            .image-container{
                position: relative;
                top: 0;
                right: 0;
                width: inherit;
    
                .mr-tan-img{
                    border: 2px solid #F1B148;
                    border-radius: 25px;
                    padding: 15px;
                    position: relative;
                    top: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    width: fit-content;
                    img{
                        width: 170px;
                    }
                }

                .qr-container-tap{
                    position: absolute;
                    width: 95px;
                    height: 100px;
                    background-color: white;
                    border-radius: 10px;
                    text-align: center;
                    top: 150px;
                    right: 130px;

                    .video-qr{
                        width: 70px;
                        padding-bottom: 6px;
                        margin-left: 12px;
                    }

                    p{
                        color: #202c51;
                        font-weight: 500;
                        font-size: 12px;
                        margin: 0;
                        margin-left: auto;
                        margin-right: auto;
                        padding-top: 3px;
                    }
                }

                .qr-container{
                    position: absolute;
                    width: 95px;
                    height: 100px;
                    background-color: white;
                    border-radius: 10px;
                    text-align: center;
                    top: 150px;
                    right: 130px;
                    transition: all .2s ease-in-out;
                    z-index: 1;

                    .video-qr{
                        width: 60px;
                        padding-bottom: 6px;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    p{
                        color: #202c51;
                        font-weight: 500;
                        font-size: 10px;
                        margin: 0;
                        margin-left: auto;
                        margin-right: auto;
                        padding-top: 3px;
                    }
                    &:hover {
                        transform: scale(2);
                    }
                }
            }

            .mr-tan-text{
                position: relative;
                top: 25px;
                left: 100px;

                .mr-tan-name{
                    color: white;
                    font-size: 22px;
                    font-weight: 600;
                    padding-top: 5px;
                    text-align: left;
                }

                .paragraph{
                    color: white;
                    text-align: left;
                    font-size: 13px;
                }

                .photo-credit{
                    color: white;
                    font-size: 10px;
                    margin: 0;
                    text-align: left;
                    font-style: italic;
                    padding-top: 3px;
                }
            }
            
            .copyright {
                font-size: 0.75rem;
                font-family: $font_barlow;
                font-weight: 200;
                padding: 40px 10px 5px 5px;
                text-align: center;
                color: white;
              }
        
              .hol {
                font-size: 0.75rem;
                font-family: $font_barlow;
                font-weight: 200;
                padding: 0px 10px 15px 10px;
                text-align: center;
                color: white;
        
                .destyled-link {
                  font-weight: 500;
                  transition: color 0.3s;
                  &:hover {
                    color: $theme-color;
                  }
                }
            }

            .begin-btn-container{
                padding: 20px 0 0 0;
                width: inherit;
                margin: 0;

                .begin-btn{
                    background-color: #f1b148;
                    font-weight: 600;
                    color: $popup-color;
                    @include responsive-shadow;
                
                    &:hover {
                        background-color: #e9c182;
                    }
                }
            }
        }
    }
}

//launch video
.mr-tan-video{
    height: 100vh;
    width: auto;
    margin-left: -120px;
    position: absolute;
    z-index: 0;
}

.loader{
    position: fixed;
    width: 90px;
    margin: auto;
    top: -60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

// virtual launch video
.launch-video-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    background-color: rgba($color: #131827, $alpha: 0.9);

    .user-camera{
        position: absolute;
        background-color: black;
        //width: 100vw;
        height: 100vh;
        // animation: topright 1s;
        // animation-fill-mode: forwards;
        z-index: 1;
    }

    .battery-icon{
        position: absolute;
        width: 30px;
        right: 4%;
        top: 1.3%;
        margin: auto;
        text-align: center;
        z-index: 10;
    }

    .celluar{
        position: absolute;
        color: white;
        font-size: 15px;
        font-weight: 700;
        right: 18%;
        top: 1%;
        margin: auto;
        text-align: center;
        z-index: 10;
    }

    .time{
        position: absolute;
        //width: 30px;
        color: white;
        font-size: 15px;
        font-weight: 700;
        left: 4%;
        top: 1%;
        margin: auto;
        text-align: center;
        z-index: 10;
    }

    .control-panel{
        position: absolute;
        height: 170px;
        width: inherit;
        border-radius: 25px 25px 0 0;
        background-color: rgba($color: #131827, $alpha: 0.9);
        text-align: center;
        bottom: 0;
        z-index: 1;

        .camera-icon{
            width: 90px;
            padding: 10px;
            position:relative;
            top: calc(50% - 70px);
        }

        .mute-icon{
            width: 90px;
            padding: 10px;
            position:relative;
            top: calc(50% - 70px);
        }

        .flip-icon{
            width: 90px;
            padding: 10px;
            position:relative;
            top: calc(50% - 70px);
        }

        .end-btn{
            width: 90px;
            padding: 10px;
            position:relative;
            top: calc(50% - 70px);
        }
    }

    //calling background
    .calling-bg{
        position: absolute;
        margin-left: 0;
        margin-right: 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #131827, $alpha: 0.7);
        z-index: 2;

        .mr-tan-name{
            position: absolute;
            color: white;
            font-size: 32px;
            left: 0;
            right: 0;
            top: 23%;
            margin: auto;
            text-align: center;
            z-index: 5;
        }

        .call-message{
            position: absolute;
            color: white;
            font-size: 25px;
            opacity: 0.7;
            left: 0;
            right: 0;
            top: 29%;
            margin: auto;
            text-align: center;
            z-index: 5;
        }

        .remind-icon{
            position: absolute;
            width: 90px;
            left: 15%;
            bottom: 40%;
            margin: auto;
            text-align: center;
            z-index: 5;
        }

        .message-icon{
            position: absolute;
            width: 90px;
            right: 15%;
            bottom: 40%;
            margin: auto;
            text-align: center;
            z-index: 5;
        }

        .decline-btn{
            position: absolute;
            width: 80px;
            left: 15%;
            bottom: 15%;
            margin: auto;
            text-align: center;
            z-index: 5;
        }

        .accept-btn{
            position: absolute;
            width: 80px;
            right: 15%;
            bottom: 15%;
            margin: auto;
            text-align: center;
            z-index: 5;
        }

        audio {
            opacity: 0;
            pointer-events: none;
            display: none;
        }
    }

    //decline message
    .decline-message{
        position: absolute;
        height: 200px;
        width: 300px;
        background-color: white;
        border-radius: 25px;
        padding: 20px;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        text-align: center;
        z-index: 6;

        .header{
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 10px;
        }

        .text{
            font-size: 15px;
        }

        .stay-btn{
            position: absolute;
            color: #0066FF;
            //background-color: black;
            border-radius: 0 0 0 25px;
            border: lightgray solid 2px;
            width: 150px;
            height: 75px;
            bottom: 0;
            left: 0;
            font-size: 18px;
            font-weight: 500;
            padding-top: 25px;
        }

        .leave-btn{
            position: absolute;
            color: #E70000;
            border-radius: 0 0 25px 0;
            border: lightgray solid 2px;
            //background-color: black;
            height: 75px;
            width: 150px;
            bottom: 0;
            right: 0;
            font-size: 18px;
            font-weight: 500;
            padding-top: 25px;
        }
    }

    //end message
    .end-message{
        position: absolute;
        height: fit-content;
        width: fit-content;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        text-align: center;
        z-index: 6;

        .end-text{
            font-size: 30px;
            color: white;
            padding-bottom: 150px;
        }

        .visit-tour{
            width: 250px;
            height: 60px;
            font-size: 15px;
            font-weight: 500;
            color: white;
            background-color: #64C255;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        .close-window{
            width: 250px;
            height: 60px;
            font-size: 15px;
            font-weight: 500;
            color: white;
            background-color: #C4C4C4;
            border-radius: 10px;
        }
    }
}

.launch-qr-code{
    height: 30vh;
    padding: 15px;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 10px;
}

.visit-button-container{
    position: relative;
    bottom: -75%;

    .visit-tour{
        margin:0 auto;
        display:block;
        width: 250px;
        height: 60px;
        background-color: #f1b148;
        font-weight: 600;
        color: $popup-color;
        @include responsive-shadow;
    
        &:hover {
            background-color: #e9c182;
        }
    }
}

@include media-sm{
    .author-container {
        .container{
            max-width: 650px;
        }

        h1{
            font-size: 18px;
        }

        .mr-tan-message{
            padding-top: 90px;
    
            .image-container{
                margin-left: 5px;
    
                .mr-tan-img{
                    img{
                        width: 150px;
                    }
                }

                .sidebar-qrvideo-container{
                    top: 100px;
                    left: 140px;
                }
            }
        }

        .introduction-video-container{
            .cards-container {
                width: 95%;
                .flip-card {
                  .flip-card-inner {
                    width: 130px;
                    height: 320px;

                    .flip-card-back{
                        .imgback{
                            height: 52%;
                        }

                        .detail-section-wrapper {
                            .details{
                                margin-top: 170px;

                                .header {
                                    font-size: 14px;
                                }

                                .text {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                  }
                }
            }
        }

        .sticker-pack{
            .sticker-details{
                .sticker-gif{
                    width: 200px;
                }
            }
        }
    }

    .pop-up-container{
        .pop-up{
            height: 350px;

            .btn-top-right {
                top: 7%;
                right: 4%;
            }
        }
    }
}

@include media-md-sm{
    .author-container {

        h1{
            font-size: 20px;
        }

        .container{
            max-width: 720px;
        }

        .mr-tan-message{
            padding-top: 90px;
    
            .image-container{
                margin-left: 30px;
    
                .mr-tan-img{
                    img{
                        width: 150px;
                    }
                }

                .sidebar-qrvideo-container{
                    top: 100px;
                    left: 140px;
                }
            }
        }
        .introduction-video-container{
            .cards-container {
                width: 100%;
                .flip-card {
                  .flip-card-inner {
                    width: 150px;
                    height: 290px;

                    .flip-card-back{
                        .imgback{
                            height: 60%;
                        }

                        .detail-section-wrapper {
                            .details{
                                margin-top: 170px;

                                .header {
                                    font-size: 14px;
                                }

                                .text {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                  }
                }
            }
        }
        .sticker-pack{
            .sticker-details{
                .sticker-gif{
                    width: 200px;
                }
            }
        }
    }

    .pop-up-container{
        .pop-up{
            height: 350px;

            .btn-top-right {
                top: 6%;
                right: 4%;
            }
        }
    }
}

@include media-md{
    .author-container {
        .container{
            max-width: 800px;
            padding-left: 15px;
        }

        h1{
            font-size: 20px;
        }

        .mr-tan-message{
            padding-top: 90px;

            .image-container{

                .mr-tan-img{
                    img{
                        width: 170px;
                    }
                }

                .sidebar-qrvideo-container{
                    top: 125px;
                    left: 150px;
                }
            }
        }
        .introduction-video-container{
            .cards-container {
                width: 100%;
                .flip-card {
                  .flip-card-inner {
                    width: 150px;
                    height: 297px;

                    .flip-card-back{
                        .imgback{
                            height: 60%;
                        }

                        .detail-section-wrapper {
                            .details{
                                margin-top: 170px;

                                .header {
                                    font-size: 14px;
                                }

                                .text {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                  }
                }
            }
        }

        .sticker-pack{
            .sticker-details{
                .sticker-gif{
                    width: 200px;
                }
            }
        }
    }

    .pop-up-container{
        .pop-up{
            height: 350px;

            .btn-top-right {
                top: 6%;
                right: 3%;
            }
            
            .popup-details-container{
                .begin-btn-container{
                    padding-left: 15%;
                }
            }
        }
    }
}

//popup & giveaway for tablets
@media screen and (min-width: 900px) {
    .author-container {
        .container{
            max-width: 900px;
        }
    }

    .pop-up-container{
        .pop-up{
            height: 500px;

            .btn-top-right {
                top: 6%;
                right: 3%;
            }
            
            .popup-details-container{
                .begin-btn-container{
                    padding-left: 5%;
                }
            }
        }
    }
}

@include media-lg{
    .author-container {
        .pd50{
            padding-left: 70px;
        }

        h1{
            font-size: 24px;
        }
        .container{
            max-width: 1200px;
        }
        .mr-tan-message{
            padding-top: 90px;
    
            .image-container{
                margin-left: 100px;
    
                .mr-tan-img{
                    img{
                        width: 180px;
                    }
                }

                .sidebar-qrvideo-container{
                    top: 135px;
                    left: 150px;
                    &:hover {
                        transform: scale(2);
                    }
                }
            }
        }
        .introduction-video-container{
            padding: 0 0 30px 0;
            .cards-container {  
                width: 100%;
                .flip-card {
                  .flip-card-inner {
                    width: 230px;
                    height: 380px;

                    .flip-card-back{
                        .imgback{
                            height: 68%;
                        }
                        
                        .detail-section-wrapper {
                            .details{
                                margin-top: 250px;

                                .header {
                                    font-size: 15px;
                                }

                                .text {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                  }
                }
            }
        }

        .sticker-pack{
            .sticker-details{
                .sticker-gif{
                    width: 250px;
                }
            }
        }
    }

    .pop-up-container{
        .pop-up{
            height: 574px;

            .btn-top-right {
                top: 4%;
                right: 3%;
            }

            .popup-details-container{
                .begin-btn-container{
                    padding-left: 0;
                }
            }
        }
    }
}

@keyframes glow {
    from {
    // what you want to happen at the start goes here
    text-shadow:  0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0;
    }
    to {
    // what you want to happen at the end goes here
    text-shadow:  0 0 20px #fff, 0 0 30px #fff;
    }
}

@keyframes anim-glow {
    0% {
      box-shadow: 0 0 rgba(#f1b148, 1);
    }
    100% {
      box-shadow: 0 0 10px 8px transparent;
    }
}

@keyframes topright {
    from {
        top: 0px;
        right: 0px;
    }
    
    to{
        background-color: transparent;
        height: 190px;
        width: 120px;
        top: 30px;
        right: 15px;
    }
}