.landing-bg {
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-attachment: fixed;
  background-color: rgba(#131827, 0.9);
  // background-size: cover;
  // background-image: url("../static/image/overlay_bg/tommykoh_bg.png");
}

.landing-wrapper {
  position: absolute;
  @extend .full-screen;

  .vid {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// desktop styling
.intro-video-container {
  text-align: center;
  z-index: 1;
  color: white;
  height: 100%;
  @include opacity-0;
  @include transition-ease(opacity, 2s);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  div h1 {
    font-size: 2em;
    font-family: $font_merriweather;
    font-weight: 700;
  }

  div p {
    font-family: $font_barlow;
    font-weight: 400;
  }

  .ytb-iframe-wrapper {
    position: relative;

    iframe {
      height: 60vh;
    }
  }

  .landing-button {
    @include transition-ease(opacity, 2s);
  }

  .btn-orange {
    background-color: #f1b148;
    color: $popup-color;
    @include responsive-shadow;

    &:hover {
      background-color: #e9c182;
    }
  }

  video {
    max-width: 100%;
    max-height: 70vh;
  }
}

// mobile styling
.intro-video-container-mobile {
  text-align: center;
  z-index: 1;
  color: white;
  height: 100%;
  @include opacity-0;
  @include transition-ease(opacity, 2s);
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  align-items: center;

  div h1 {
    font-size: 2em;
    font-family: $font_merriweather;
    font-weight: 700;
  }

  div p {
    font-family: $font_barlow;
    font-weight: 400;
  }

  .ytb-iframe-wrapper {
    position: relative;

    iframe {
      width: 75%;
      height: 50vh;
    }
  }

  .landing-button {
    @include transition-ease(opacity, 2s);
    padding-bottom: 35px;
  }

  .btn-orange {
    background-color: #f1b148;
    color: $popup-color;
    @include responsive-shadow;
  }

  video {
    max-width: 100%;
    max-height: 70vh;
  }
}
