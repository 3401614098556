// @import "~bootstrap/scss/bootstrap";

@import "config";
@import "home";
@import "tour";
@import "responsive";
@import "mobile";
@import "new-introduction";

//safari fix?
body,
html {
  padding: env(safe-area-inset);
}

a.destyled-link {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.min-full-screen {
  min-width: 100vw;
  min-height: 100vh;
}

.full-screen-black-bg {
  background-color: black;
  @include full-screen;
  @include top-left;
}

.full-screen-black-bg__half {
  @extend .full-screen-black-bg;
  background-color: rgba($color: #131827, $alpha: 0.9);
}

.full-screen-black-bg__transparent {
  @extend .full-screen-black-bg;
  background-color: rgba($color: #131827, $alpha: 0.5);
}

.full-screen-white-bg__half {
  @extend .full-screen-black-bg;
  background-color: rgba($color: white, $alpha: 0.9);
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.responsive-btn {
  @include responsive-btn;
}

.responsive-shadow {
  @include responsive-shadow;
}

.pre-line {
  white-space: pre-line;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  overflow-y: hidden;
  -ms-overflow-y: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #545454;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d8c8c;
}

.border-bottom-white {
  border-bottom: 3px solid white;
}

.border-bottom-insert {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.border-bottom-insert::after {
  content: "";
  position: relative;
  margin-top: 5px;

  height: 100%;
  width: 100%;
  display: block;
  border-top: 2px solid $theme-color;
  animation: bottom-border-insert 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  pointer-events: none;
  transform: translateX(-100%);
}

.border-bottom-black {
  border-bottom: 3px solid black;
}

.border-bottom-theme {
  border-bottom: 2px solid $theme-color;
}

.border-left-insert {
  position: relative;
}

.border-left-insert > span {
  position: relative;
  display: block;
  // height: 100%;
  // width: 100%;
  padding-left: 7px;
  // border-left: 1px solid $theme-color;
  animation: left-border-insert 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;
  transform: translateX(-100%);
}

.border-left-insert::after {
  content: "";
  border-left: 1px solid $theme-color;

  display: block;

  transform: translateY(-100%);
  height: 100%;
  width: 100%;

  pointer-events: none;
  position: absolute;
}

@keyframes bottom-border-insert {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes left-border-insert {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.scroll-prompt {
  position: fixed;

  bottom: 75px;
  pointer-events: none;

  &__white span {
    border-left: 2px solid white;
    border-bottom: 2px solid white;
  }

  &__black span {
    border-left: 2px solid black;
    border-bottom: 2px solid black;
  }

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;

    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: scrollPrompt 2s infinite;
    animation: scrollPrompt 2s infinite;
    opacity: 0;
    box-sizing: border-box;
  }

  span:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  span:nth-of-type(2) {
    top: 16px;
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }
  span:nth-of-type(3) {
    top: 32px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
}
@-webkit-keyframes scrollPrompt {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scrollPrompt {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hidden-audio-player {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.font-13 {
  font-size: 13px !important;
}

.font-18 {
  font-size: 18px !important;
}

.hover-zoom {
  transition: transform 0.3s;
  transform: scale(1, 1);
}

.hover-zoom:hover {
  transform: scale(1.2, 1.2);
}
