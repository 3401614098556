body,
html {
  overflow: auto;
  font-size: 12px;
}

.main-layout-wrapper {
  .sidebar-container {
    width: 150px;
    padding-left: 5px;
    padding-right: 5px;

    .main-title-container {
      padding: 5px 10px 5px 10px;
    }
  }
  .sidebar-container.inactive {
    margin-left: -100px;
  }
}
.four-cards-container {
  // overflow: auto;
  .flip-card {
    padding-left: 5px;
    padding-right: 5px;
    .flip-card-inner {
      width: 140px;
      height: 350px;
    }
  }
}

.cards-container {
  .flip-card {
    padding-left: 5px;
    padding-right: 5px;
    .flip-card-inner {
      width: 140px;
      height: 280px;
    }
  }
}

.four-cards-container-mobile {
  // overflow: auto;
  .flip-card {
    padding-left: 5px;
    padding-right: 5px;
    .flip-card-inner {
      width: 140px;
      height: 350px;
    }
  }
}

.story-cards-container {
  .cards-wrapper {
    height: 100px;
    .card {
      img {
        width: 185px;
      }
    }
  }
}

.super-hero-container {
  .student-img {
    width: 100px;
  }

  .student-detail-wrapper {
    .hero-photo {
      width: 250px;
      height: 300px;
    }

    .audio-btns-wrapper {
      .audio-item {
        padding: 5px;
      }
    }
  }
}

.superhero-mobile{
  .super-hero-container-mobile {
    .mobile-row{
      margin-left: 5%;
      margin-top: 1%;
    }

    .student-img {
      width: 100px;
    }
  
    .student-detail-wrapper {
      .hero-photo {
        width: 250px;
        height: 300px;
      }
  
      .audio-btns-wrapper {
        .audio-item {
          padding: 5px;
        }
      }
    }
  }
}

.ME-container {
  .challenge-wrapper {
    width: 400px;
  }

  .altitude-wrapper {
    bottom: 20px;
    right: 20px;
    width: 250px;
  }

  .info-wrapper.info-wrapper__0 {
    width: 270px;
    left: 26%;
    top: 15%;
  }

  .info-wrapper.info-wrapper__1 {
    width: 300px;
    left: 54%;
    top: 15%;
  }

  .info-wrapper.info-wrapper__2 {
    width: 300px;
    left: 63%;
    top: 8%;
  }

  .info-wrapper.info-wrapper__3 {
    width: 300px;
    left: 70%;
    top: 5%;
  }
}

.ME-container-mobile {
  .info-wrapper{
    right: 5%;
    left: 5%;

    .img-wrapper {
      width: 419px;
    }

    .row-wrapper{
      .text{
        width: 332px;
      }
    }
  }

  .ME-ending-container {
    .left-wrapper{
      .btn-row {
        column-gap: 10px;
      }
    }
  }
}

.TCM-container {
  .tutorial-card-container {
    width: 24%;
    height: 300px;
  }
}

.container,
.container-fluid {
  overflow-x: hidden;
  overflow-y: auto;
}

.wicare-container {
  .sm-charity-box,
  .bg-charity-box {
    width: 150px;
  }
}

.theme-btn,
.theme-btn-auto {
  font-size: 10px;
}

.community-service-help-container {
  .fixed-height {
    height: 120px;
  }
}

.map-container {
  .body {
    .map-wrapper {
      .hotspot-wrapper {
        .text-wrapper {
          font-size: 5px;
        }
      }
    }
  }
}

.map-container-mobile {
  .body {
    .map-wrapper {
      .hotspot-wrapper {
        .text-wrapper {
          font-size: 7.8px;
        }
      }
    }
  }
}

.intro-video-container {
  .logo {
    width: 150px;
  }
}

.artcanvas-container{
  .watermark-logo{
    width: 250px;
    left: 5%;
  }
}

@include media-sm{
  .paragraph {
    font-size: 1.1rem;
  }

  .flythrough-container-mobile{
    .container{
        max-width: 720px;
    }
  }

  .ME-container-mobile {
    .info-wrapper{
      right: 1%;
      left: 1%;
      .frame-wrapper{
        height: 200px;
      }

      .img-wrapper {
        width: 390px;
      }

      .row-wrapper{
        .text{
          width: 312px;
        }
      }
    }

    .ME-ending-container {
      .left-wrapper{
        .btn-row {
          display: flex;
          column-gap: 1px;
        }
      }

      .ending-video-wrapper {
        iframe{
          width: 55vw;
          height: 50vh;
        }
      }
    }
  }

  .superpower-container-mobile{
    .container{
      .input-row{
        height: 60px;
        .input-wrapper{
          input{
            font-size: 25px;
          }
        }
      }
    }
  }

  .superhero-mobile{
    .super-hero-container-mobile{
      .mobile-row{
        margin-left: 0%;
        margin-top: 1%;

        span.paragraph {
          font-size: 13px;
        }
      }

      .student-detail-wrapper {
        .photo-wrapper{
          .hero-photo {
            height: 90vh;
            width: 35vw;
          }
        }

        .photo-wrapper-mobile-audio{
          .hero-photo {
            width: 750px;
            bottom: 1px;
            left: 5px;
          }
        }

        .audio-btns-wrapper {
          .audio-item {
            padding: 10px 10px 10px 10px;
          }
        }
        
        .hint-wrapper {
          margin-left: 23%;
        }
      }
    }
  }

  .intro-video-container-mobile {
    .logo {
      width: 165px;
    }
  }

  .education-values-master{
    .mobile-values{
      margin-left: 2px;
    }
  }

  .icons-wrapper {
    right: 13px;
    height: 65%;
    
    .circle-icon{
      height: 35px;
      width: 35px;
    }
  }

  .share-modal-container{
    width: 100% !important;
  }

  .share-artwork{
    height: 150px;
  }

  .artwork-container{
    .download-icon{
      bottom: 5%;
      right: 25%;
    }
  }

  .share-container-mobile{
    margin-left: 45%;
  }

  .artcanvas-container{  
    .watermark-logo{
      width: 150px;
      left: 5%;
    }
  }
}

@include media-md-sm{

  .paragraph {
    font-family: $font_barlow;
    font-weight: 300;
    font-size: 1.1rem;
  }

  .flythrough-container-mobile{
    .container{
        max-width: 720px;
    }
  }

  .ME-container-mobile {

    .info-wrapper{
      .img-wrapper {
        width: 420px;
      }

      .row-wrapper{
        .text{
          width: 312px;
        }
      }
    }

    .ME-ending-container {
      .left-wrapper{
        .btn-row {
          display: flex;
          column-gap: 2px;
        }
      }

      .ending-video-wrapper {
        iframe{
          width: 60vw;
          height: 65vh;
        }
      }
    }
  }

  .superpower-container-mobile{
    .container{
      .input-row{
        height: 65px;
        .input-wrapper{
          input{
            font-size: 30px;
          }
        }
      }
    }
  }

  .intro-video-container-mobile {
    .logo {
      width: 165px;
    }
  }

  .superhero-mobile{
    .super-hero-container-mobile{
      .mobile-row{
        margin-left: 0%;
        margin-top: 1%;

        span.paragraph {
          font-size: 14.8px;
        }
      }

      .student-detail-wrapper {
        .photo-wrapper{
          .hero-photo {
            height: 95vh;
            width: 38vw;
          }
        }

        .photo-wrapper-mobile-audio{
          .hero-photo {
            width: 760px;
          }
        }
  
        .audio-btns-wrapper {
          .audio-item {
            padding: 10px 15px 10px 15px;
          }
        }
        
        .hint-wrapper {
          margin-left: 19%;
        }
      }
    }
  }

  .education-values-master{
    .mobile-values{
      margin-left: 2px;
    }
  }

  .icons-wrapper {
    right: 13px;
    height: 60%;
    
    .circle-icon{
      height: 40px;
      width: 40px;
    }
  }

  .share-modal-container{
    width: 100% !important;
  }

  .artwork-container{
    .download-icon{
      bottom: 5%;
      right: 25%;
    }
  }

  .share-container-mobile{
    margin-left: 40%;
  }

  .artcanvas-container{  
    .watermark-logo{
      width: 160px;
      left: 5%;
    }
  }
}

@include media-md {
  body,
  html {
    overflow: auto;
    font-size: 14px;
  }

  .paragraph{
    font-size: 0.9rem;
  }

  .main-layout-wrapper {
    .sidebar-container {
      width: 200px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .sidebar-container.inactive {
      margin-left: -200px;
    }
  }

  .theme-btn,
  .theme-btn-auto {
    font-size: 14px;
  }

  .flythrough-container-mobile{
    .container{
        max-width: 800px;
    }
  }

  .book-launch-container{
    .container{
      max-width: 800px;
    }
  }

  .four-cards-container {
    .flip-card {
      .flip-card-inner {
        width: 190px;
        height: 380px;
      }
    }
  }

  .cards-container {
    .flip-card {
      .flip-card-inner {
        width: 190px;
        height: 310px;
      }
    }
  }

  .four-cards-container-mobile {
    .flip-card {
      .flip-card-inner {
        width: 170px;
        height: 380px;
      }
    }
  }

  .story-cards-container {
    .cards-wrapper {
      height: 200px;
      .card {
        img {
          width: 250px;
        }
      }
    }
  }

  .super-hero-container {
    .student-img {
      width: 160px;
    }

    .student-detail-wrapper {
      .hero-photo {
        width: 400px;
        height: 450px;
      }

      .audio-btns-wrapper {
        .audio-item {
          padding: 10px 15px 10px 15px;
        }
      }
    }
  }

  .superhero-mobile{
    .super-hero-container-mobile {
      .mobile-row{
        margin-left: 5%;
        margin-top: 1%;

        span.paragraph {
          font-size: 15px;
        }
      }

      .student-img {
        width: 160px;
      }

      .student-detail-wrapper {
        .photo-wrapper{
          .hero-photo {
            height: 95vh;
            width: 40vw;
          }
        }

        .audio-btns-wrapper {
          .audio-item {
            padding: 10px 15px 10px 15px;
          }
        }

        .photo-wrapper-mobile-audio{
          .hero-photo {
            width: 750px;
          }
        }
        
        .hint-wrapper {
          margin-left: 15%;
        }
      }
    }
  }

  .superpower-container-mobile{
    .container{
      .input-row{
        height: 70px;
        .input-wrapper{
          input{
            font-size: 40px;
          }
        }
      }
    }
  }

  .ME-container {
    .challenge-wrapper {
      width: 400px;
    }

    .altitude-wrapper {
      bottom: 20px;
      right: 20px;
      width: 250px;
    }

    .info-wrapper.info-wrapper__0 {
      width: 270px;
      left: 26%;
      top: 15%;
    }

    .info-wrapper.info-wrapper__1 {
      width: 300px;
      left: 54%;
      top: 15%;
    }

    .info-wrapper.info-wrapper__2 {
      width: 300px;
      left: 63%;
      top: 8%;
    }

    .info-wrapper.info-wrapper__3 {
      width: 300px;
      left: 70%;
      top: 5%;
    }
  }

  .ME-container-mobile {
    .info-wrapper{
      right: 5%;
      left: 5%;

      .img-wrapper {
        width: 418px;

        img{
          height: 260px;
        }
      }

      .row-wrapper{
        .text{
          width: 332px;
        }
      }
    }

    .ME-ending-container {
      .left-wrapper{
        .btn-row {
          column-gap: 10px;
        }
      }

      .ending-video-wrapper {
        iframe{
          width: 70vw;
          height: 65vh;
        }
      }
    }
  }

  .TCM-container {
    .tutorial-card-container {
      width: 24%;
      height: 250px;
    }
  }

  .share-modal-container{
    width: 100% !important;
  }

  .artwork-container{
    .download-icon{
      bottom: 5%;
      right: 30%;
    }
  }

  .wicare-container {
    .sm-charity-box,
    .bg-charity-box {
      width: 200px;
    }
  }

  .container,
  .container-fluid {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .community-service-help-container {
    .fixed-height {
      height: 100px;
    }
  }

  .map-container {
    .body {
      .map-wrapper {
        .hotspot-wrapper {
          .text-wrapper {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  //desktop styling for home
  .intro-video-container {
    .logo {
      width: 175px;
    }
  }

  //mobile styling for home
  .intro-video-container-mobile {
    .logo {
      width: 170px;
      margin-bottom: 5px;
    }
  }

  .education-values-master{
    .mobile-values{
      margin-left: 30px;
    }
  }

  .icons-wrapper {
    right: 15px;
    height: 70%;
    
    .circle-icon{
      height: 40px;
      width: 40px;
    }
  }

  .share-container-mobile{
    margin-left: 50%;
  }

  .share-artwork{
    height: 165px;
  }

  .artcanvas-container{  
    .watermark-logo{
      width: 180px;
      left: 5%;
    }
  }
}

@include media-lg {
  body,
  html {
    overflow: hidden;
    font-size: 16px;
  }

  .main-layout-wrapper {
    .sidebar-container {
      width: 300px;
      padding-left: 15px;
      padding-right: 15px;

      .main-title-container {
        height: 170px;
        padding: 10px 20px 10px 20px;
      }
    }
    .sidebar-container.inactive {
      margin-left: -300px;
    }
  }

  .education-values-master {
    .header {
      font-size: 40px;
    }
  }

  .theme-btn,
  .theme-btn-auto {
    font-size: 16px;
  }

  .book-launch-container{
    .container{
      max-width: 1140px;
    }
  }

  .four-cards-container {
    .flip-card {
      .flip-card-inner {
        width: 230px;
        height: 450px;
      }
    }
  }

  .cards-container {
    .flip-card {
      .flip-card-inner {
        width: 230px;
        height: 380px;
      }
    }
  }

  .story-cards-container {
    .cards-wrapper {
      height: 310px;
      .card {
        img {
          width: 400px;
        }
      }
    }
  }

  .super-hero-container {
    .student-img {
      width: 250px;
    }

    .student-detail-wrapper {
      .hero-photo {
        width: 493px;
        height: 600px;
      }
    }
  }

  .superhero-mobile{
    .super-hero-container-mobile {
      .student-img {
        width: 250px;
      }
  
      .student-detail-wrapper {
        .hero-photo {
          width: 550px;
          height: 600px;
        }
      }
    }
  }

  .ME-container {
    .challenge-wrapper {
      width: 550px;
    }

    .altitude-wrapper {
      bottom: 40px;
      right: 40px;
      width: 300px;
      font-size: 18px;
    }

    .info-wrapper.info-wrapper__0 {
      width: 350px;
      left: 23%;
      top: 34%;
    }

    .info-wrapper.info-wrapper__1 {
      width: 350px;
      left: 51%;
      top: 18%;
    }

    .info-wrapper.info-wrapper__2 {
      width: 350px;
      left: 61%;
      top: 8%;
    }

    .info-wrapper.info-wrapper__3 {
      width: 350px;
      left: 73%;
      top: 5%;
    }
  }

  .ME-container-mobile {
    .info-wrapper{
      right: 5%;
      left: 5%;

      .img-wrapper {
        width: 418px;

        img{
          height: 260px;
        }
      }

      .row-wrapper{
        .text{
          width: 332px;
        }
      }
    }

    .ME-ending-container {
      .left-wrapper{
        .header-row {
          margin-top: 10%;
        }

        .btn-row {
          column-gap: 10px;
        }
      }

      .ending-video-wrapper {
        iframe{
          width: 70vw;
          height: 65vh;
        }
      }
    }
  }

  .TCM-container {
    .tutorial-card-container {
      width: 24%;
      height: 500px;
    }
  }

  .wicare-container {
    .sm-charity-box,
    .bg-charity-box {
      width: 300px;
    }
  }

  .container,
  .container-fluid {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .community-service-help-container {
    .fixed-height {
      height: 70px;
    }
  }

  .intro-video-container {
    .logo {
      width: 200px;
    }
  }
  
  .icons-wrapper {
    right: 25px;
    height: 50%;
    
    .circle-icon{
      height: 50px;
      width: 50px;
    }
  }

  .share-modal-container{
    width: 60% !important;
  }

  .share-artwork{
    height: 300px;
  }

  .artwork-container{
    .download-icon{
      bottom: 5%;
      right: 20%;
    }
  }

  .artcanvas-container{  
    .watermark-logo{
      width: 250px;
      left: 5%;
    }
  }
}
