//styling for landscape mode message
.landscape-check-bg {
    position: fixed;
    height: 110%;
    width: 100%;
    background-attachment: fixed;
    background-color: rgba(#202c51, 1);
    z-index: 10001;
    background-position: center;
    background-size: cover;

    .landscape-message-logo{
        position: absolute;
        height: 20vh;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -200%);
    }

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: large;
    color: white;

    .rotate-img{
        height: 25vh;
        padding: 15px;
        align-self: center;
    }
  }
}

//styling for low-power message (iphone)
.low-power-message-bg {
  height: 10vh;
  width: 100vw;
  position: fixed;
  background-color: rgba($color: #131827, $alpha: 0.9);
  z-index: 1000;

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
    font-size: large;
    color: white;
  }

  .img{
    top: 5%;
    position: absolute;
    height: 30vh;
    padding: 20px;
  }

  .close-btn{
    top: 70%;
    position: absolute;
    text-align: center;
  }
}

//Styling for mobile menu
// mobile side-bar css
.mobile-icon{
  position: absolute;
  top: 35px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2;
  
  div {
    width: 2.5rem;
    height: 0.2rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    background: #202c51;
    position: relative;
    transform-origin: 5px;
    filter: drop-shadow();
  }

  :first-child {
    transform: rotate(0);
  }

  :nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  :nth-child(3) {
    transform: rotate(0);
  }
}

.mobile-menu{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 60vw;
  text-align: left;
  padding: 20px 60px;
  background: #202c51;
  background-image: url("../static/image_mobile/sidebar/leaves_bg.webp");
  background-size: 130% 130%;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  overflow-y: scroll;

  @media (max-width: 576px) {
    width: 100%;
  }

  .menu-item {
    font-size: 15px;
    padding: 7px 0;
    letter-spacing: 0.02rem;
    text-decoration: none;
    color: white;
    transition: color 0.3s linear;
    
    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    .logo{
      margin-bottom: 10px;
      width: 140px;
    }
  }

  //giveaway
  .menu-item-redeem {
    font-size: 15px;
    padding: 7px 0;
    letter-spacing: 0.02rem;
    text-decoration: none;
    color: white;
    transition: color 0.3s linear;
    //animation: anim-glow 1s ease-in-out infinite alternate;
    
    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    .logo{
      margin-bottom: 10px;
      width: 140px;
    }
  }

  .logo_container{
    text-align: center;
    border-bottom: 1px solid #F1B148;
    margin-bottom: 5px;
  }

  .fas{
    margin-left: 10px;
    padding: 4px;
  }

  .akn{
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    margin-bottom: 1px;
  }

  .footer{
    font-size: 10px;
    align-items: center;
    text-align: center;
    color: white;

    .bold{
      font-weight: bold;
    }
  }

  .copyright{
    color: #F1B148;
  }

  .dropdown{
    padding: 0 0;

    .item{
      padding-top: 7px;
    }
  }
}
.mobile-menu::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
  display: none;
}

.mobile-tutorial{
  .return-button{
    bottom: 30px;
    position: absolute;
    left: 40%;
  }
}