.top-left {
  @include top-left;
}

.main-layout-wrapper {
  position: absolute;
  @include top-left;
  @include full-screen;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  .iframe-wrapper {
    justify-self: stretch;
    height: 100%;
    width: 100%;
    position: relative;

    .sidebar-switch {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    #showcase-iframe {
      height: 100%;
      width: 100%;
    }
  }

  .sidebar-container {
    height: 100%;
    transition: all 0.3s;
    background-image: url("../static/image/sidebar/sidebar_bg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #202c51;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .main-title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid white;

      .header {
        text-align: center;
        font-family: $font_merriweather;
        font-size: 1.1rem;
        font-weight: 700;
        color: $theme-color;
        display: flex;
        flex-direction: column;

        .the {
          font-size: 0.85rem;
          font-weight: 400;
        }
      }

      .subheader {
        padding-top: 10px;
        text-align: center;
        font-family: $font_barlow;
        font-size: 0.8rem;
        font-weight: 300;
        color: white;
      }

      .logo {
        width: 100%;
      }
    }

    .items-container {
      position: relative;
      display: flex;
      flex-direction: column;

      justify-content: flex-start;
      align-items: center;
      padding: 0;

      .item {
        padding: 15px 10px 15px 10px;
        width: 100%;
        background-color: transparent;
        color: white;
        font-size: 0.9rem;
        font-family: $font_barlow;
        font-weight: 400;

        border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
        white-space: pre-line;
      }

      .item:hover {
        background-color: white;
        color: black;
        cursor: pointer;
      }

      //giveaway
      .item-redeem {
        padding: 15px 10px 15px 10px;
        width: 100%;
        background-color: transparent;
        color: white;
        font-size: 0.9rem;
        font-family: $font_barlow;
        font-weight: 400;
        border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
        white-space: pre-line;
      }

      .item-redeem:hover {
        background-color: white;
        color: black;
        cursor: pointer;
      }
    }

    //end of giveaway

    .react-slidedown {
      width: 100%;
      overflow-x: hidden;
    }

    .slidedown-wrapper {
      background-color: rgba($color: #fbf7f5, $alpha: 0.15);
      display: flex;
      flex-direction: column;

      padding: 0;

      .item {
        position: relative;
        color: white;
        font-size: 0.8rem;
        font-family: $font_barlow;
        font-weight: 400;
        padding: 15px 10px 15px 10px;
        border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
      }
      .item:hover {
        background-color: white;
        color: black;
        cursor: pointer;
      }

      .item.schools {
        border-bottom: none;
      }

      .item.schools::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 2.5%;
        width: 95%;
        border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
      }
    }

    .akn {
      display: flex;
      justify-content: space-between;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0;
      color: white;

      .functions {
        align-self: end;
        border: 1px solid white;
        border-left: none;
        border-right: none;
        width: 100%;
        display: flex;
        color: white;
        font-size: 0.9rem;
        font-family: $font_barlow;
        font-weight: 700;

        .sub-item {
          padding: 15px 10px 15px 10px;
          @extend .clickable;
          text-align: center;
        }
        .sub-item:hover {
          background-color: white;
          color: black;
          cursor: pointer;
        }

        .sub-item:nth-child(1) {
          border-right: 1px solid white;
        }
      }

      .copyright {
        font-size: 0.75rem;
        font-family: $font_barlow;
        font-weight: 200;
        padding: 15px 10px 5px 10px;
        text-align: center;
        color: $theme-color;
      }

      .hol {
        font-size: 0.75rem;
        font-family: $font_barlow;
        font-weight: 200;
        padding: 0px 10px 15px 10px;
        text-align: center;
        color: white;

        .destyled-link {
          font-weight: 500;
          transition: color 0.3s;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }

    .item.active,.item-redeem.active,
    .sub-item.active {
      color: $theme-color;
    }
  }
}

.tour-overlay {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
}

.popup-container {
  position: relative;
  margin-top: 8vh;
  //background-color: #202c51;
  background-image: url("../static/image/overlay_bg/intro_bg.png");

  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-clip: padding-box;
  overflow: auto auto;
  height: 75%;

  @include fade-in(0.8s);

  .pie {
    margin-left: 13%;
    margin-top: 65vh;

    @include media-md {
      margin-top: 15%;
    }
  }
}

.four-cards-container {
  position: relative;
  background-color: rgba($color: #131827, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  // gap: 15px;

  .header {
    color: white;
  }

  .cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 2%;
  }

  .flip-card {
    background-color: transparent;
    perspective: 1000px;
    height: 100%;
    cursor: pointer;
    position: relative;

    .flip-card-inner {
      position: relative;

      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      .flip-card-front,
      .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-position: center;
        background-size: cover;
        -webkit-perspective: 0;
        -webkit-transform: translate3d(0, 0, 0);

        img {
          width: 100%;
          position: absolute;
        }

        img.type__0 {
          top: 0;
          right: 0;

          object-position: left top;
          object-fit: contain;
        }

        img.type__1 {
          left: 0;
          bottom: 0px;
          object-position: bottom right;
          object-fit: contain;
        }
      }

      .flip-card-front {
        background-color: #202c51;

        .food-section-wrapper {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          color: white;
          z-index: 5;

          .header {
            font-size: 1.4rem;
            font-family: $font_merriweather;
            font-weight: 700;
            text-align: start;
          }

          .small-text {
            font-size: 0.8rem;

            text-align: start;
            font-family: $font_barlow;
            font-weight: 300;

            overflow: hidden;
          }
        }

        .bottom-bar {
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: -4px 0 4px 2px rgba(80, 79, 79, 0.438);

          span {
            text-align: center;
            color: black;
            text-transform: uppercase;
            font-family: $font_barlow;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      .flip-card-back {
        background-color: #fbf7f5;
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);

        .quote-container {
          border: 1px solid #f1b148;
          background-color: #fbf7f5;
          width: 120px;
          color: #202c51;
          font-size: 9px;
          font-family: $font_merriweather;
          font-size: 700;
          position: absolute;
          padding: 10px;
          text-align: start;
        }

        .detail-section-wrapper {
          padding: 10px;

          @include media-lg {
            padding: 20px;
          }

          display: flex;

          color: black;
          flex-direction: column;

          text-align: start;
          overflow: hidden;
          z-index: 5;
          .name {
            font-size: 1.25rem;

            font-family: $font_merriweather;

            font-weight: 700;
            white-space: pre-line;
          }

          .year {
            font-family: $font_barlow;
            font-weight: 600;
            font-style: italic;
            font-size: 0.9rem;
          }

          .details {
            display: flex;
            flex-direction: column;
            border-left: 2px solid $theme-color;
            padding-left: 7px;
            margin-top: 3px;

            br {
              display: block;
              margin: 5px 0;
            }

            .subtitle {
              font-size: 0.65rem;
              font-family: $font_barlow;

              font-weight: 600;
              line-height: 1.1;
            }

            .text {
              font-size: 0.65rem;

              font-family: $font_barlow;

              font-weight: 400;
              white-space: pre-line;
            }
          }
        }
      }
    }

    &.active {
      .flip-card-inner {
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
      }
    }

    // .header {
    //   font-family: $font_barlow;
    //   font-weight: 600 !important;
    //   color: white;
    //   text-align: center;
    // }

    .overflow {
      height: 70vh;
      overflow: auto;
    }

    @include media-lg {
      .overflow {
        overflow: visible;
      }
    }
  }

  .inactive {
    .border-left-animation {
      @extend .border-left-insert;
    }
  }

  .flip-card.active {
    .flip-card-front .food-section-wrapper {
      .border-left-animation {
        position: relative;
        display: block;
        padding-left: 7px;
        border-left: 1px solid $theme-color;
        pointer-events: none;
      }
    }
  }
}

// .four-cards-container-mobile::-webkit-scrollbar:vertical {
//   width: 12px;
// }

// .four-cards-container-mobile::-webkit-scrollbar-thumb {
//   background-color: blue;    /* color of the scroll thumb */
//   border-radius: 20px;       /* roundness of the scroll thumb */
//   border: 3px solid orange;  /* creates padding around scroll thumb */
// }

.four-cards-container-mobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-bottom: 20px;
  background-color: rgba($color: #131827, $alpha: 0.5);

  .header-main {
    padding-top: 270px;
    color: white;

    font-size: 1.6rem;
    font-family: "Merriweather", serif;
    font-weight: 700;
    text-align: start;
  }

  .header {
    color: white;
  }

  .cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 2%;
  }

  .flip-card {
    background-color: transparent;
    perspective: 1000px;
    height: 100%;
    cursor: pointer;
    position: relative;

    .flip-card-inner {
      position: relative;

      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      .flip-card-front,
      .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-position: center;
        background-size: cover;
        -webkit-perspective: 0;
        -webkit-transform: translate3d(0, 0, 0);

        img {
          width: 100%;
          position: absolute;
        }

        img.type__0 {
          top: 0;
          right: 0;

          object-position: left top;
          object-fit: contain;
        }

        img.type__1 {
          left: 0;
          bottom: 0px;
          object-position: bottom right;
          object-fit: contain;
        }
      }

      .flip-card-front {
        background-color: #202c51;

        .food-section-wrapper {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          color: white;
          z-index: 5;

          .header {
            font-size: 1.4rem;
            font-family: $font_merriweather;
            font-weight: 700;
            text-align: start;
          }

          .small-text {
            font-size: 0.8rem;

            text-align: start;
            font-family: $font_barlow;
            font-weight: 300;

            overflow: hidden;
          }
        }

        .bottom-bar {
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: -4px 0 4px 2px rgba(80, 79, 79, 0.438);

          span {
            text-align: center;
            color: black;
            text-transform: uppercase;
            font-family: $font_barlow;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      .flip-card-back {
        background-color: #fbf7f5;
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);

        .quote-container {
          border: 1px solid #f1b148;
          background-color: #fbf7f5;
          width: 120px;
          color: #202c51;
          font-size: 9px;
          font-family: $font_merriweather;
          font-size: 700;
          position: absolute;
          padding: 10px;
          text-align: start;
        }

        .detail-section-wrapper {
          padding: 10px;

          @include media-lg {
            padding: 20px;
          }

          display: flex;

          color: black;
          flex-direction: column;

          text-align: start;
          overflow: hidden;
          z-index: 5;
          .name {
            font-size: 1.25rem;

            font-family: $font_merriweather;

            font-weight: 700;
            white-space: pre-line;
          }

          .year {
            font-family: $font_barlow;
            font-weight: 600;
            font-style: italic;
            font-size: 0.9rem;
          }

          .details {
            display: flex;
            flex-direction: column;
            border-left: 2px solid $theme-color;
            padding-left: 7px;
            margin-top: 3px;

            br {
              display: block;
              margin: 5px 0;
            }

            .subtitle {
              font-size: 0.65rem;
              font-family: $font_barlow;

              font-weight: 600;
              line-height: 1.1;
            }

            .text {
              font-size: 0.65rem;

              font-family: $font_barlow;

              font-weight: 400;
              white-space: pre-line;
            }
          }
        }
      }
    }

    &.active {
      .flip-card-inner {
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
      }
    }

    .overflow {
      height: 70vh;
      overflow: auto;
    }

    @include media-lg {
      .overflow {
        overflow: visible;
      }
    }
  }

  .inactive {
    .border-left-animation {
      @extend .border-left-insert;
    }
  }

  .flip-card.active {
    .flip-card-front .food-section-wrapper {
      .border-left-animation {
        position: relative;
        display: block;
        padding-left: 7px;
        border-left: 1px solid $theme-color;
        pointer-events: none;
      }
    }
  }
}

.popup-header {
  font-family: $font_merriweather;
  font-weight: 700 !important;
  color: $theme-color;
}

.long-header {
  font-size: 28px;
}

.popup-sub-header {
  font-family: $font_barlow;
  font-weight: 600 !important;
  color: $theme-color;
  border-bottom: 3px solid $theme-color;
}

.popup-text {
  font-family: $font_barlow;
  font-weight: 400 !important;
  color: $text-color;
}

.paragraph-format {
  white-space: pre-line;
}

.white-box {
  border-radius: 20px;
  background-color: white;
  text-align: center;
  font-size: 13px;
  color: $popup-color;
  &__text {
    font-family: $font_merriweather;
    font-weight: 700 !important;
  }

  &__name {
    font-family: $font_barlow, serif;
    font-weight: 400 !important;
  }
}

.btn-top-right {
  position: absolute;
  @include top-right;
  padding: 8px;
  width: 2.8em;
  z-index: 99;

  a img {
    width: 100%;
    height: auto;
    transition: all 0.3s;
    @include responsive-btn;
  }
}

.top-right {
  @include top-right;
}

.ellipse {
  height: 100%;
}

.slide-down-header {
  .ellipse-container {
    height: 100%;
  }

  &__text {
    text-transform: capitalize;
    font-family: $font_barlow;
    font-weight: 600 !important;
    color: white;
    border-bottom: 3px solid white;
  }

  &__arrow {
    position: absolute;
    right: 15px;
    top: 5px;
    width: 13px;
    @include media-md {
      width: 16px;
      top: 3px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.slide-down-content {
  display: none;
  div {
    margin-left: 4%;
  }

  @include media-md {
    div {
      margin-left: 14%;
    }
  }

  ul li {
    color: white;
    font-family: $font_barlow;
    font-size: 13px;
  }
}

.music-wrapper {
  position: absolute;
  z-index: 0;
  width: 25px;
  bottom: 25px;
  left: 148px;
  @extend .clickable;

  audio {
    opacity: 0;
    pointer-events: none;
    display: none;
  }
}

.h-15 {
  height: 15%;
}

.tommy-koh-container {
  background-image: url("../static/image/overlay_bg/tommykoh_bg.png");
  .text-section {
    height: 48vh;
    overflow-y: scroll;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.debug-button {
  right: 0;
  top: 0;
  position: absolute;
  z-index: 99;
}

.intro-option__left {
  position: absolute;
  top: 40%;
  z-index: 1;
  transform-style: preserve-3d;
  left: 20%;
}

.intro-option__right {
  position: absolute;
  top: 40%;
  z-index: 1;
  transform-style: preserve-3d;
  right: 20%;
}

.timeline-container {
  background-image: url("../static/image/timeline/timeline_bg.png");
  background-size: 100% auto;
  background-position: top;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-clip: padding-box;
  background-attachment: local;

  font-family: $font_barlow;
  font-weight: 600 !important;
  color: white;

  margin-top: 1%;
  height: 90%;
  overflow-x: hidden;

  h1 {
    font-size: 1.5em;

    @include media-md {
      font-size: 2.5em;
    }
  }

  .lines {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .timeline-item {
    position: absolute;
    transition: opacity 1s;

    .timeline-item__text {
      @include media-md {
        font-size: 14px;
      }

      width: 100%;
      text-align: left;
      font-weight: 400 !important;
      font-size: 8px;
    }

    .timeline-item__img {
      width: 100%;
    }
  }

  .indicator-container {
    display: none;

    @include media-lg {
      display: block;
      position: absolute;
      right: 50%;
      top: 0;
      width: 10px;
      .indicator {
        position: fixed;
        background-color: transparent;
        border: 2px solid white;
        width: 10px;
        height: 10px;
        border-radius: 50%;

        cursor: pointer;
      }

      .indicator:nth-child(1) {
        top: 20vh;
      }
      .indicator:nth-child(2) {
        top: 23vh;
      }
      .indicator:nth-child(3) {
        top: 26vh;
      }
      .indicator:nth-child(4) {
        top: 29vh;
      }
    }
  }

  //text
  .timeline-layout :nth-child(2) {
    top: 10.5%;
    left: 11.2%;
    width: 38%;
  }

  .timeline-layout :nth-child(3) {
    top: 30.5%;
    left: 52%;
    width: 38%;
  }

  .timeline-layout :nth-child(4) {
    top: 53%;
    left: 11.2%;
    width: 38%;
  }

  .timeline-layout :nth-child(5) {
    top: 78.5%;
    right: 10.5%;
    width: 38%;
  }
  //images

  .timeline-layout :nth-child(6) {
    transform-origin: 0 50%;
    width: 30%;
    top: 11%;
    right: 15%;
  }

  .timeline-layout :nth-child(7) {
    transform-origin: 100% 50%;
    width: 29%;
    top: 26%;
    left: 14%;
  }

  .timeline-layout :nth-child(8) {
    transform-origin: 0 50%;
    width: 20%;
    top: 45%;
    right: 23%;
  }

  .timeline-layout :nth-child(9) {
    transform-origin: 100% 50%;
    width: 28%;
    top: 69%;
    left: 15%;
  }

  //voice icon

  .timeline-layout :nth-child(10) {
    top: 6%;
    left: 22%;
  }
  .timeline-layout :nth-child(11) {
    top: 26%;
    right: 32%;
  }

  .timeline-layout :nth-child(12) {
    top: 48.5%;
    left: 22%;
  }

  .timeline-layout :nth-child(13) {
    top: 74.3%;
    right: 31%;
  }

  .timeline-layout .voice-container {
    @extend .clickable;
    width: 5%;
    position: absolute;
  }
}

.clickable {
  cursor: pointer;
  pointer-events: initial;
}

.unclickable {
  pointer-events: none !important;
}

.story-cards-container {
  color: white;

  .indicator-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;

    right: 25px;
    top: 40px;
    height: 100vh;
    @extend .unclickable;
    z-index: 50;

    .indicator {
      position: relative;

      background-color: transparent;
      border: 2px solid white;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      @extend .clickable;
    }

    .indicator.active {
      background-color: white;
    }
  }

  .header {
    font-family: $font_barlow;
    font-weight: 400;
    font-size: 2rem;
  }

  .paragraph {
    font-family: $font_barlow;
    font-weight: 300;
    font-size: 0.85rem;
  }

  .cards-wrapper {
    position: relative;
    .card {
      position: absolute;
      background-color: transparent;
      border-color: transparent;

      box-shadow: none;
      top: 0;
      left: 0;

      img {
        width: 400px;
        pointer-events: none;
        transform-origin: center;
        box-shadow: 4px 5px 5px rgba($color: #414141, $alpha: 0.5);
      }

      img::selection {
        color: transparent;
        background-color: transparent;
      }
    }

    .card.transition-transform {
      @include transition-ease(transform, 1.5s);
    }

    :nth-child(1) {
      z-index: 10;
      img {
        transform: rotate(4deg);
        -webkit-transform: rotate(4deg);
      }
    }
    :nth-child(2) {
      z-index: 9;
      img {
        transform: rotate(7deg);
        -webkit-transform: rotate(7deg);
      }
    }

    :nth-child(3) {
      z-index: 8;
      img {
        transform: rotate(-1deg);
        -webkit-transform: rotate(-1deg);
      }
    }
    :nth-child(4) {
      z-index: 7;
      img {
        transform: rotate(-4deg);
        -webkit-transform: rotate(-4deg);
      }
    }
    :nth-child(5) {
      z-index: 6;
      img {
        transform: rotate(3deg);
        -webkit-transform: rotate(3deg);
      }
    }
  }
}

.community-service-intro-container {
  color: white;

  canvas {
    background-color: transparent;
  }

  .indicator-container {
    display: block;
    position: absolute;
    right: 10%;
    top: 0;
    width: 10px;
    .indicator {
      position: fixed;
      background-color: transparent;
      border: 2px solid white;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      cursor: pointer;
    }

    .indicator.active {
      background-color: white;
    }

    .indicator:nth-child(1) {
      top: 40vh;
    }
    .indicator:nth-child(2) {
      top: 43vh;
    }
    .indicator:nth-child(3) {
      top: 46vh;
    }
    .indicator:nth-child(4) {
      top: 49vh;
    }
  }
}

.wicare-container {
  color: white;

  .border-bottom-half-white {
    border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
  }

  .sm-charity-box,
  .bg-charity-box {
    position: absolute;
    background-color: rgba($color: #202c51, $alpha: 0.8);
    font-family: $font_barlow;
    opacity: 0;

    border: 1px solid rgba($color: #ffffff, $alpha: 0.5);

    .header {
      font-size: 1rem;
      font-weight: 700;
    }
    .paragraph {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  .sm-charity-box {
    left: 20%;
    bottom: 15%;
  }

  .bg-charity-box {
    left: 33%;
    bottom: 25%;
  }
}

$map-size: 125%;

.community-service-help-container {
  @extend .community-service-intro-container;

  .main-wrapper {
    .left-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .slider-wrapper {
        transition: all 0.8s;

        .rc-slider-handle {
          border: none;
          border-radius: 0;
          background-image: url("../static/image/community_service_help/heart-red.webp");
          background-position: top left;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          width: 42px;
          height: 42px;
          top: -25%;
        }

        .rc-slider-dot {
          display: none;
        }
        .rc-slider-mark-text {
          color: white;
          font-size: 16px;
          top: 10px;
        }
      }

      .below-slider {
        margin-top: 15%;
      }
    }

    .end-btn {
      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;
      color: #202c51;
      background-color: white;
      padding: 10px 15px 10px 15px;
      text-align: center;
      height: 70px;
    }
  }

  .stage-0-wrapper {
    transition: all 0.5s;
  }

  .question {
    font-size: 15px;
    padding-bottom: 3px;

    @include media-lg {
      font-size: 20px;
      padding-bottom: 5px;
    }

    font-family: $font_barlow;
    font-weight: 600;
  }

  .stage-1-wrapper .col-auto button {
    width: 150px;
    font-size: 12px;
    padding: 15px;
    @include media-lg {
      width: 200px;
      font-size: 16px;
      padding: 20px;
    }
  }

  .map {
    width: $map-size;
  }

  .big-bubble {
    width: 40%;
    position: absolute;
    top: 0;
    left: 35%;
  }

  .bubbles-wrapper {
    position: absolute;
    width: $map-size;
    height: 100%;

    @include top-left;

    // @include transition-ease(opacity, 1.5s);

    .bubble {
      position: absolute;
      width: 8%;
      opacity: 0;
    }
  }

  .confirm-btn {
    @include transition-ease(opacity, 1s);
  }

  .rc-slider-disabled {
    background-color: transparent !important;
  }
}

.art-therapy-intro-container {
  color: white;

  @include transition-ease(opacity, 2s);

  .to-canvas-btn {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid white;
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .to-canvas-btn:hover {
    background-color: rgba(0, 0, 0, 0.438);
  }

  .quote {
    font-family: $font_merriweather;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: #202c51;
  }

  img.paint-anim {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-25%, -50%);
    width: 150%;
  }
}

.art-therapy-ending-container {
  height: 100%;
  color: white;
  position: absolute;
  @extend .top-left;
  z-index: 5;
  right: 0;
}

.art-therapy-loading-container {
  height: 100vh;
  width: 100vw;
  color: white;
  background-color: rgba(19, 24, 39, 1);
  position: absolute;
  @extend .top-left;
  z-index: 5;
  right: 0;
}

.artcanvas-container {
  width: 100vw;
  height: 100vh;

  .art-therapy-canvas {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .artwork-border {
    width: 100vw;
    height: 100vh;
    //border: 10px solid #202c51;
    background-color: rgba($color: #131827, $alpha: 0.9);
  }

  .watermark-logo {
    @include bottom-right;
    position: fixed;
    margin-top: 40%;
    z-index: 99999;
  }
}

.hint-text {
  color: white;

  font-size: 30px;
  display: block;
  @include transition-ease(opacity, 1.5s);
  opacity: 0;

  pointer-events: none;
}

.dementia-intro-container {
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include transition-ease(opacity, 1s);

  .intro-col,
  .button-col {
    color: white;
  }

  .intro-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 5%;

    .row {
      width: 100%;
    }
    .italic {
      font-family: $font_barlow;
      font-weight: 200;
      font-style: italic;
    }
  }

  .button-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      white-space: pre-line;
      font-family: $font_barlow;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
    }
  }
}

.dementia-game-container {
  color: white;
  // opacity: 0;
  // @include transition-ease(opacity, 1s);

  .counter-col {
    display: flex;
    justify-content: center;
    align-items: center;

    .base-timer {
      position: relative;
      width: 300px;
      // height: 300px;
    }

    .base-timer-mobile {
      position: relative;
      top: -10%;
      width: 200px;
    }

    .base-timer__svg {
      transform: scaleX(-1);
    }

    .base-timer__circle {
      fill: none;
      stroke: none;
    }

    .base-timer__path-elapsed {
      stroke-width: 7px;
      stroke: grey;
    }

    .base-timer__path-remaining {
      stroke-width: 7px;
      stroke-linecap: round;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform-origin: center;
      transition: 1s linear all;
      fill-rule: nonzero;
      stroke: currentColor;
    }

    .base-timer__path-remaining.green {
      color: rgb(65, 184, 131);
    }

    .base-timer__path-remaining.orange {
      color: orange;
    }

    .base-timer__path-remaining.red {
      color: red;
    }

    .base-timer__label {
      position: absolute;
      width: 100%;

      top: 50%;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4vw;
      background-color: transparent !important;
    }

    .base-timer__label.green {
      color: rgb(65, 184, 131);
    }

    .base-timer__label.orange {
      color: orange;
    }

    .base-timer__label.red {
      color: red;
    }
  }

  .counter-wrapper .counters {
    height: 10%;
    p {
      margin: auto;
    }
  }

  .game-start-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #131827, $alpha: 0.9);
    @include top-left;

    .header {
      width: 400px;
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .game-start-btn {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid white;
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cards-col .cards-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .cards-row {
      justify-content: center;

      .flip-card {
        background-color: transparent;
        perspective: 1000px;

        width: 60px;
        height: 60px;
        padding: 5px;
        @include media-md {
          width: 90px;
          height: 90px;
          padding: 5px;
        }

        @include media-lg {
          width: 150px;
          height: 150px;
          padding: 15px;
        }

        @include transition-ease(opacity, 0.5s);

        .flip-card-inner {
          position: relative;
          width: 100%;
          height: 100%;

          text-align: center;
          transition: transform 0.5s;
          transform-style: preserve-3d;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

          .flip-card-front,
          .flip-card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
          }

          .flip-card-front {
            background-color: #ffd171;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .flip-card-back {
            background-color: #ffd171;
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
            color: $popup-color;
            font-family: $font_barlow;
            text-align: left;
            font-size: 14px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        &.active {
          .flip-card-inner {
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
          }
        }

        &.active.after-start {
          .flip-card-back {
            background-color: #89c4ff;
          }
        }

        &.active.matched {
          .flip-card-back {
            background-color: #9bcb3c;
          }
        }

        &.active.not-matched {
          .flip-card-back {
            background-color: #ee0e51;
          }
        }

        &.inactive {
          .flip-card-inner {
            transform: rotateY(0);
            -webkit-transform: rotateY(0);
          }
        }
      }
    }
  }

  .flip-card.clickable {
    cursor: pointer;
  }
  .flip-card.unclickable {
    pointer-events: none;
  }

  .border-white {
    border: 2px white solid;
  }
}

.dementia-ending-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  color: white;

  .header {
    border-bottom: 1px solid $theme-color;
  }

  .btn-row {
    display: flex;
    gap: 25px;
    justify-content: center;
  }
}

.dementia-ending-container-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  color: white;

  .header {
    border-bottom: 1px solid $theme-color;
  }

  .btn-row {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
}

.super-hero-container {
  color: white;
  max-width: 1350px !important;

  .student-card {
    // border: 2px solid $theme-color;
    // margin-left: 20px;
    padding: 0;

    &:before {
      transition: all 0.4s;
      position: absolute;
      content: "";
      width: 102%;
      height: 50%;
      left: 49%;
      margin-left: -50%;
      top: 25%;
      border-color: rgba(200, 200, 200, 0);
      border-style: solid;
      border-width: 2px;
    }

    &:after {
      transition: all 0.4s;
      position: absolute;
      content: "";
      width: 50%;
      height: 100%;
      left: 50%;
      margin-left: -25%;
      top: 0;
      border-color: rgba(200, 200, 200, 0);
      border-style: solid;
      border-width: 2px 0;
    }

    &:hover {
      color: #4099ff;

      &:after {
        width: 100%;
        margin-left: -50%;
        border-color: $theme-color;
      }

      &:before {
        height: 100%;
        top: 0%;
        border-color: $theme-color;
      }
    }
  }

  .student-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 35% center;
  }

  .student-img {
    @include transition-ease(filter, 0.5s);
    filter: grayscale(1);
  }

  .student-card.darken {
    filter: brightness(0.4);
  }

  .student-detail-wrapper {
    position: absolute;

    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;

    .photo-wrapper {
      position: relative;

      .hero-photo {
        margin-top: -7%;
        position: relative;
        // margin-left: -40px;
        object-fit: cover;
        object-position: center;
      }

      .btn-back {
        color: white;
        cursor: pointer;
        background-color: rgba($color: #12192e, $alpha: 0.8);
        padding: 10px;
        width: auto;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-family: Roboto;
        font-weight: bold;
        text-transform: uppercase;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        border: 1px solid white;
        z-index: 6;
        min-width: 30%;
      }
      .btn-back:hover {
        background-color: white;
        color: #12192e;
      }
    }

    .hint-wrapper {
      transform: translateX(-50%);
      margin-left: 50%;
    }

    .audio-btns-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      top: 0;
      left: 0;

      .audio-item {
        position: absolute;
        display: flex;
        flex-direction: row;
        gap: 10px;
        color: white;
        align-items: center;
        border-radius: 30px;
        border: 2px solid white;
        background-color: rgba($color: #202c51, $alpha: 0.8);

        font-size: 0.8rem;

        span {
          cursor: pointer;
          pointer-events: all;
        }
        span.paragraph {
          font-weight: 600;
        }
      }

      .audio-item:hover {
        color: #202c51;
        background-color: white;
      }
    }
  }
}

.superhero-mobile {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  .super-hero-container-mobile {
    max-width: 1350px !important;
    color: white;

    .btn-arrow {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
    }

    .btn-arrow-disabled {
      border: solid grey;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
    }

    .mobile-row {
      margin-left: 5%;
      margin-top: 1%;
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    .student-card-mobile {
      // border: 2px solid $theme-color;
      // margin-left: 20px;
      padding: 0;

      .student-group-img {
        height: 350px;
      }
    }

    .row {
      .paragraph {
        margin-top: 10px;
        font-size: 15px;
      }
    }

    .student-animation {
      height: 350px;
    }

    .student-img {
      @include transition-ease(filter, 0.5s);
      filter: grayscale(1);
    }

    .student-card.darken {
      filter: brightness(0.4);
    }

    .student-detail-wrapper {
      position: absolute;
      left: 50%;
      bottom: 2%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;

      .photo-wrapper {
        .hero-photo {
          height: 95vh;
          width: 40vw;
          position: relative;
          object-fit: contain;
          object-position: center;
        }

        .btn-back {
          color: white;
          cursor: pointer;
          background-color: rgba($color: #12192e, $alpha: 0.8);
          padding: 10px;
          width: auto;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          font-family: Roboto;
          font-weight: bold;
          text-transform: uppercase;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          color: white;
          border: 1px solid white;
          z-index: 6;
          min-width: 30%;
        }
        .btn-back:hover {
          background-color: white;
          color: #12192e;
        }
      }

      .photo-wrapper-mobile-audio {
        margin-top: -5%;
        .hero-photo {
          height: 94vh;
          width: 750px;
          bottom: 2px;
          position: relative;
          object-fit: contain;
          object-position: center;
        }

        .btn-back {
          color: white;
          cursor: pointer;
          background-color: rgba($color: #12192e, $alpha: 0.8);
          padding: 10px;
          width: auto;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          font-family: Roboto;
          font-weight: bold;
          text-transform: uppercase;
          position: absolute;
          bottom: 10px;
          left: 75%;
          transform: translateX(-50%);
          color: white;
          border: 1px solid white;
          z-index: 6;
          min-width: 30%;
        }
        .btn-back:hover {
          background-color: white;
          color: #12192e;
        }
      }

      .hint-wrapper {
        transform: translateX(-50%);
        margin-left: 15%;
      }

      .audio-btns-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        top: 0;
        left: 0;

        .audio-item {
          position: absolute;
          display: flex;
          flex-direction: row;
          gap: 10px;
          color: white;
          align-items: center;
          border-radius: 30px;
          border: 2px solid white;
          background-color: rgba($color: #202c51, $alpha: 0.8);

          font-size: 0.8rem;

          span {
            cursor: pointer;
            pointer-events: all;
            margin-top: 0;
            font-size: 14px;

            i {
              padding: 5px;
            }
          }
          span.paragraph {
            font-weight: 600;
          }
        }
      }
    }
  }

  .indicator-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;

    right: 15px;
    height: 50vh;
    @extend .unclickable;
    z-index: 3;

    .indicator {
      position: relative;

      background-color: transparent;
      border: 2px solid white;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      @extend .clickable;
    }

    .indicator.active {
      background-color: white;
    }
  }
}

.white-veil {
  @extend .full-screen-black-bg;
  background-color: rgba($color: white, $alpha: 0.8) !important;
}

.education-intro-master {
  .education-intro-container {
    color: white;
  }

  .header-container {
    color: white;
    position: absolute;
    z-index: 4;
    top: 7%;
    left: 4%;
    display: flex;
    flex-direction: column;

    .header {
      width: 130%;
    }
  }

  .student-bg,
  .classroom-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .black-veil {
    @extend .full-screen-black-bg__half;
  }

  .indicator-container {
    display: block;
    position: absolute;
    right: 5%;
    top: 0;
    width: 10px;
    .indicator {
      position: fixed;
      background-color: transparent;
      border: 2px solid white;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      cursor: pointer;
    }

    .indicator.active {
      background-color: white;
    }

    .indicator:nth-child(1) {
      top: 40vh;
    }
    .indicator:nth-child(2) {
      top: 43vh;
    }
    .indicator:nth-child(3) {
      top: 46vh;
    }
    .indicator:nth-child(4) {
      top: 49vh;
    }
    .indicator:nth-child(5) {
      top: 52vh;
    }
  }
}

.education-intro-master-mobile {
  .education-intro-container {
    color: white;
  }

  .header-container {
    color: white;
    position: absolute;
    z-index: 4;
    top: 7%;
    left: 4%;
    display: flex;
    flex-direction: column;

    .header {
      width: 120%;
      font-size: 1.7rem;
    }

    .border-bottom-insert::after {
      width: 85%;
    }
  }

  .student-bg,
  .classroom-bg {
    position: absolute;
    top: 15%;
    left: 5%;
    right: 0;
    bottom: 0;
    width: 90%;
    height: 85%;
    object-fit: cover;
  }

  .black-veil {
    @extend .full-screen-black-bg__half;
  }

  .indicator-container {
    display: block;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;

    right: 40px;
    height: 100vh;
    @extend .unclickable;
    z-index: 50;

    .indicator {
      position: fixed;
      background-color: transparent;
      border: 2px solid white;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      @extend .unclickable;
    }

    .indicator.active {
      background-color: white;
    }

    .indicator:nth-child(1) {
      top: 51vh;
    }
    .indicator:nth-child(2) {
      top: 54vh;
    }
    .indicator:nth-child(3) {
      top: 57vh;
    }
    .indicator:nth-child(4) {
      top: 60vh;
    }
    .indicator:nth-child(5) {
      top: 63vh;
    }
  }
}

.hover-grey:hover {
  background-color: rgba(80, 79, 79, 0.438);
}

.ME-container {
  color: white;

  .climbing-vid {
    object-position: left center;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
  }

  .scifi-wrapper {
    pointer-events: none;
  }

  .challenge-wrapper {
    position: fixed;
    top: 40px;
    left: 50px;

    font-family: $font_barlow;
    font-weight: 400;
    font-size: 1.1rem;

    span {
      color: white;
    }
  }

  .altitude-wrapper {
    position: fixed;

    font-family: $font_barlow;
    font-weight: 400;

    font-size: 1.1rem;

    @include media-lg {
      width: 300px;
    }
  }

  .info-wrapper {
    position: absolute;
    color: white !important;
    font-family: $font_barlow;
    font-weight: 400;
    .img-wrapper {
      padding: 20px;
      padding-bottom: 0;
      font-size: 1.1rem;

      small {
        color: white !important;
      }
    }
    p {
      font-size: 1rem;
      padding: 0 20px 0 20px;

      margin-top: 0;
    }
  }

  .info-wrapper.info-wrapper__0 {
  }

  .info-wrapper.info-wrapper__1 {
  }
  .info-wrapper.info-wrapper__2 {
  }

  .info-wrapper.info-wrapper__3 {
  }

  .ME-ending-container {
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .left-wrapper {
      padding: 15px;

      .header-row {
        border-bottom: 1px solid $theme-color;
      }

      .btn-row {
        display: flex;
        column-gap: 10px;
      }

      .header-row > span {
        @extend .header;
      }

      .paragraph-row > span {
        @extend .paragraph;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .ending-video-wrapper {
      position: relative;

      // height: 85vh;
      // width: 100vw;
      margin: 30px 25px 25px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      iframe {
        position: relative;
        max-width: 1090px;
        width: 80vw;
        height: 70vh;
      }
    }
  }
}

.ME-container-mobile {
  color: white;
  //background: #26325D;
  background: #202c51;
  position: relative;
  overflow: hidden;

  .climbing-vid {
    position: absolute;
    object-fit: cover;
    // top: 10%;
    // right: -16%;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .scifi-wrapper {
    pointer-events: none;
  }

  .challenge-wrapper {
    position: fixed;
    top: 10px;
    left: 40px;
    width: 50vw;

    font-family: $font_barlow;
    font-weight: 400;
    font-size: 1rem;

    span {
      color: white;
    }
  }

  .altitude-wrapper {
    position: fixed;
    display: block;
    @include bottom-right font-family: $font_barlow;
    font-weight: 400;

    font-size: 1rem;
    right: 4%;
    bottom: 4%;

    @include media-lg {
      width: 300px;
    }
  }

  .info-wrapper {
    width: auto;
    position: absolute;
    justify-content: center;
    top: 14%;
    right: 1.5%;
    left: 1.5%;
    z-index: 3;

    color: white !important;
    font-family: $font_barlow;
    font-weight: 400;

    .row-wrapper {
      font-size: 1.8rem;
      text-align: center;
      z-index: 2;

      .text {
        border-top: 1px solid #f1b148;
        width: 332px;
        right: 7%;
        padding: 5px 0 5px 0;
        position: absolute;
        bottom: 5%;
        left: 56%;
        color: white;
        font-size: 1.2rem;
      }
    }

    p {
      text-align: center;
    }

    .col-wrapper {
      align-content: center;
    }

    .img-wrapper {
      left: -3%;

      small {
        color: white !important;
      }

      img {
        height: 250px;
      }
    }

    p {
      font-size: 1rem;
      margin-top: 0;
    }
  }

  .info-wrapper.info-wrapper__0 {
  }

  .info-wrapper.info-wrapper__1 {
  }
  .info-wrapper.info-wrapper__2 {
  }

  .info-wrapper.info-wrapper__3 {
  }

  .ME-ending-container {
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow-y: hidden;
    height: 85vh;
    top: 50%;
    transform: translateY(-50%);

    .children-inherit-height {
      div {
        height: 100%;
      }

      .not-affected {
        height: auto;
        div {
          height: auto;
        }
      }
    }

    .left-wrapper {
      height: 350px;
      padding: 20px 8.3% 20px 0;
      //overflow-y: scroll;

      .header-row {
        border-bottom: 1px solid $theme-color;
      }

      .btn-row {
        display: flex;
      }

      .header-row > span {
        @extend .header;
      }

      .paragraph-row > span {
        @extend .paragraph;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    // .header-row,
    // .paragraph-row {
    //   position: relative;
    //   display: block;
    // }

    .ending-video-wrapper {
      position: relative;
      margin: 30px 25px 25px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      iframe {
        position: relative;
        max-width: 1090px;
        width: 75vw;
        height: 65vh;
        overflow-y: scroll;
      }
    }
  }
}

.lets-go-btn {
  cursor: pointer;
  background-color: transparent;
  border: 2px solid white;
  width: 120px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TCM-container {
  color: white;

  .tutorial-card-container {
    background-color: white;
    color: black;
    padding: 5px 10px 5px 10px;

    overflow: hidden;

    .header {
      color: black;
    }

    @include media-lg {
      width: 24%;
      height: 500px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .state-1-row {
    padding-top: 10%;
  }

  .text-span {
    font-size: 0.8rem;
  }

  .option {
    display: flex;

    justify-content: space-around;
    align-items: center;

    .indicator-wrapper {
      border-radius: 50%;

      width: 35px;
      height: 35px;
      @include media-lg {
        width: 35px;
        height: 35px;
      }

      background-color: #f1f1f1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .indicator {
        background-color: #ffffff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      .indicator.active {
        background-color: #dfe571;
      }
    }

    .option-btn {
      color: white;
      background-color: transparent;
      height: 50px;
      width: 99%;
      border: 1px solid white;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 1rem;
        font-family: $font_barlow;
        font-weight: 600;
        text-align: center;
      }
    }

    .option-btn.active {
      background-color: #9bc7ea;
      color: black;
    }

    .option-btn.inactive:hover {
      background-color: white;
      color: black;
    }
  }

  .ear-wrapper {
    padding: 0;
  }

  .italic-link {
    @extend .paragraph;
    border-bottom: 1px solid white;
    font-style: italic;
  }

  .italic-link:hover {
    color: $theme-color;
    border-bottom: 1px solid $theme-color;
  }
}

.TCM-container-mobile {
  color: white;

  .tutorial-card-container {
    background-color: white;
    color: black;
    padding: 5px 10px 5px 10px;

    overflow: hidden;

    .header {
      color: black;
    }

    @include media-lg {
      width: 24%;
      height: 500px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .mobile-tutorial-card {
      position: relative;
      height: 250px;
      .header {
        color: black;
      }
      .img-wrapper{
        position: absolute;
        bottom: 0;
        img {
          max-width: 75%;
        }
      }
    }
  }

  .state-1-row {
    padding-top: 10%;
  }

  .text-span {
    font-size: 0.8rem;
  }

  .option {
    display: flex;

    justify-content: space-around;
    align-items: center;

    .indicator-wrapper {
      border-radius: 50%;

      width: 35px;
      height: 35px;
      @include media-lg {
        width: 35px;
        height: 35px;
      }

      background-color: #f1f1f1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .indicator {
        background-color: #ffffff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      .indicator.active {
        background-color: #dfe571;
      }
    }

    .option-btn {
      color: white;
      background-color: transparent;
      height: 50px;
      width: 99%;
      border: 1px solid white;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 1rem;
        font-family: $font_barlow;
        font-weight: 600;
        text-align: center;
      }
    }

    .option-btn.active {
      background-color: #9bc7ea;
      color: black;
    }

    .option-btn.inactive:hover {
      background-color: white;
      color: black;
    }
  }

  .ear-wrapper {
    padding: 0;
  }

  //mobile
  .info-component {
    margin-top: -8%;
  }

  .italic-link {
    @extend .paragraph;
    border-bottom: 1px solid white;
    font-style: italic;
  }

  .italic-link:hover {
    color: $theme-color;
    border-bottom: 1px solid $theme-color;
  }
}

// .text-2 {
//   margin-left: calc(1rem+50px);
// }

.caregiver-container {
  .option-stage {
    @include transition-ease(opacity, 0.5s);
  }

  .background {
    @include full-screen;
    background-image: url("../static/image/caregiver/caregiver_bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba($color: #131827, $alpha: 0.9);
  }

  canvas {
    position: absolute;
    z-index: 1;
    @include top-left;
    // image-rendering: smooth;
    width: 100vw;
    height: 100vh;
    // image-rendering: auto;
    // pointer-events: none;
  }

  .container {
    color: white;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 5;
    pointer-events: none;

    .header {
      .border-bottom-insert::after {
        animation-delay: 2.5s;
      }
    }
  }

  .definition-container {
    color: white;
    position: absolute;
    border: 1px solid white;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    padding: 5px 10px 5px 10px;
    bottom: 30px;
    width: 200px;
    @include media-lg {
      width: 400px;
    }
  }

  .share-container {
    @extend .clickable;
    //display: none;
    position: relative;
    top: 50%;
    margin-left: 40%;
  }

  .share-container-mobile {
    @extend .clickable;
    //display: none;
    position: relative;
    top: 50%;
    width: 90vw;
  }
}

.superpower-container {
  canvas {
    position: absolute;
    z-index: 1;
    @include top-left;
    // image-rendering: smooth;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // image-rendering: auto;
    // pointer-events: none;
  }

  .container {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;

    .row {
      position: relative;

      .header {
        span {
          color: #f1b148;
        }
      }

      .quote {
        font-family: $font_merriweather;
        font-weight: 700;
      }
    }
    .input-row {
      height: 70px;

      .input-wrapper {
        padding: 0;
        // border: 2px solid white;
        border: 1px solid white;
        input {
          color: white;
          font-size: 50px;
          font-family: $font_barlow;

          height: 100%;
          width: 100%;
          border: 1px solid white;
          background-color: rgba($color: white, $alpha: 0.3);
          outline-color: white;
        }
      }

      .btn-wrapper {
        border: 2px solid white;
        background-color: white;
        margin-left: 0;
      }
    }
  }
}

.superpower-container-mobile {
  canvas {
    position: absolute;
    z-index: 1;
    @include top-left;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  .container {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;
    overflow: hidden;

    .row {
      position: relative;

      .header {
        span {
          color: #f1b148;
        }
      }

      .quote {
        font-family: $font_merriweather;
        font-weight: 700;
      }
    }
    .input-row {
      height: 60px;

      .input-wrapper {
        padding: 0;
        // border: 2px solid white;
        border: 1px solid white;
        input {
          color: white;
          font-size: 45px;
          font-family: $font_barlow;

          height: 100%;
          width: 100%;
          border: 1px solid white;
          background-color: rgba($color: white, $alpha: 0.3);
          outline-color: white;
        }
      }

      .btn-wrapper {
        border: 2px solid white;
        background-color: white;
        margin-left: 0;
      }
    }
  }
}

.intro-option-container {
  // desktop
  .who-wrapper,
  .what-wrapper {
    height: 70%;
    background-color: rgba($color: white, $alpha: 0.5);
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    .picture-wrapper {
      width: 100%;
      height: 80%;
      overflow: hidden;

      .main-img {
        position: relative;

        transition: all 0.8s;

        &__who {
          object-fit: cover;
          object-position: top center;
          width: 100%;
          height: 100%;
        }

        &__what {
          height: 100%;
        }
      }

      .questionmark-wrapper {
        position: absolute;
        @include top-left;
        pointer-events: none;
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s;

        img {
          width: 35%;
        }
      }

      .gradient-layer-wrapper {
        position: absolute;
        @include top-left;
        pointer-events: none;
        width: inherit;
        height: inherit;
        background: linear-gradient(
          180deg,
          rgba(32, 44, 81, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transition: all 0.5s;
        opacity: 0;

        span.text-wrapper {
          display: block;
          padding: 20px;
          color: white;
          font-family: $font_barlow;
          font-size: 24px;
          font-weight: 600;
          text-align: start;
        }
      }
    }
    .question-wrapper {
      width: 100%;
      height: 20%;

      background-color: #202c51;
      display: flex;
      justify-content: center;
      align-items: center;

      // box-shadow: 0 -4px 4px 2px rgba(80, 79, 79, 0.438);

      span {
        color: #fbf7f5;
        text-align: center;
        font-family: $font_barlow;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  //mobile version
  .who-wrapper-mobile,
  .what-wrapper-mobile {
    height: 80%;
    background-color: rgba($color: white, $alpha: 0.5);
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    .picture-wrapper {
      width: 100%;
      height: 85%;
      overflow: hidden;

      .main-img {
        position: relative;

        transition: all 0.8s;

        &__who {
          object-fit: cover;
          object-position: top center;
          width: 100%;
          height: 100%;
        }

        &__what {
          height: 100%;
        }
      }

      .gradient-layer-wrapper {
        position: absolute;
        @include top-left;
        pointer-events: none;
        width: inherit;
        height: inherit;
        background: linear-gradient(
          180deg,
          rgba(32, 44, 81, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transition: all 0.5s;
        opacity: 1;

        span.text-wrapper {
          display: block;
          padding: 20px;
          color: white;
          font-family: $font_barlow;
          font-size: 20px;
          font-weight: 600;
          text-align: start;
        }
      }
    }
    .question-wrapper {
      width: 100%;
      height: 15%;

      background-color: #202c51;
      display: flex;
      justify-content: center;
      align-items: center;

      // box-shadow: 0 -4px 4px 2px rgba(80, 79, 79, 0.438);

      span {
        color: #fbf7f5;
        text-align: center;
        font-family: $font_barlow;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .who-wrapper.active,
  .what-wrapper.active {
    .main-img__what {
      transform: scale(1.2, 1.2) translateX(-25%);
    }
    .main-img__who {
      transform: scale(1.2, 1.2) translateY(5%);
    }
    .gradient-layer-wrapper {
      opacity: 1;
    }

    .questionmark-wrapper {
      opacity: 0;
    }

    .question-wrapper {
      background-color: #fbf7f5;
      span {
        color: #202c51;
      }
    }
  }
}

.education-values-master {
  color: white;

  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }

  .paragraph {
    .italic {
      padding-bottom: 3px;
    }
  }

  .paragraph-mobile {
    font-size: 1.17rem;
    .italic {
      padding-bottom: 3px;
    }
  }

  .mobile-values {
    margin-left: 30px;
  }
}

.library-container {
  background-color: white;

  .library-model {
    position: relative;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  .video-wrapper-mobile {
    .library-model-mobile {
      position: relative;
      width: 100vw;
      object-fit: cover;
    }
  }

  .intro-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba($color: #131827, $alpha: 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;
  }
}

.theme-btn {
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid white;
  width: 150px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
  span {
    text-align: center;
  }
}

.theme-btn-auto {
  color: white;
  cursor: pointer;
  background-color: transparent;
  padding: 10px;
  border: 1px solid white;
  width: auto;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
}

.theme-hover:hover {
  background-color: white;
  color: black;
}

.margin-fix {
  position: relative;
  white-space: pre;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

.opacity-0 {
  @include opacity-0;
}

.opacity-1 {
  opacity: 1;
}

.left-border-yellow {
  position: relative;
  display: block;
  padding-left: 7px;
  border-left: 1px solid $theme-color;
  // animation: left-border-insert 0.5s;
  // animation-delay: 0.5s;
  // animation-fill-mode: forwards;
  // pointer-events: none;
  // transform: translateY(-100%);
}

.share-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  font-family: Roboto;
  z-index: 51;

  padding-top: 20px;
  padding-bottom: 20px;

  .header {
    color: #202c51;
    font-weight: bold;
    font-size: 20px;
  }

  .des {
    color: #737374;
    font-weight: normal;
    font-size: 12px;
    i {
      font-style: italic;
    }
  }

  .des-artwork {
    @extend .des;
    padding-left: 21px;
  }

  .copy-btn {
    color: #202c51;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid black;
    background-color: transparent;
    padding: 10px 15px 10px 15px;
    text-transform: uppercase;
  }

  .copy-btn:hover {
    background-color: #202c51;
    color: white;
  }
}

.link-area {
  background-color: #ececec;
  border: 1px solid #d4d4d4;
  height: 60px;

  color: #737374;

  display: flex;
  flex-direction: column;
  justify-content: center;

  textarea {
    @include media-md {
      margin-top: 15px;
    }

    border: none;
    background-color: transparent;
    resize: none;
    outline: none;

    font-family: Roboto;
  }
}

.link-icon-div {
  background-color: #202c51;
  border: 1px solid #d4d4d4;
}

.social-icon-div {
  height: 55px;
  background-color: #202c51;
  border: 1px solid #d4d4d4;
}

.link-icon {
  color: white;
}
.link-icon:hover {
  color: #737374;
}

.artwork-container {
  position: relative;

  img {
    display: block;
  }

  .download-icon {
    position: absolute;
    color: white;
  }
  .download-icon:hover {
    color: #737374;
  }
}

// .share-artwork{
//   border: 1px solid #d4d4d4;
// }

.social-offset {
  margin-left: 5px;
}

.email-text {
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 0px;
}

.message {
  color: green;
  font-size: 12px;
}

.icons-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 50;

  .circle-icon {
    border-radius: 50%;
    position: relative;
    border: 2px solid white;
    margin-bottom: 15px;
  }

  i {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translateX(-50%) translateY(-50%);
  }

  .circle-icon:hover {
    transform: scale(1.2, 1.2);
  }
}

.feedback-master-container {
  width: 100%;
  height: 100%;
  position: absolute;
  @extend .top-left;
  background-color: rgba($color: #131827, $alpha: 0.9);
  z-index: 5;

  .feedback-container {
    height: 100%;
    color: white;

    .error {
      color: orange;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .header {
        display: flex;
        justify-content: center;
      }
      .paragraph {
        text-align: center;
      }

      .leaf-row {
        img {
          @extend .clickable;
        }
      }
    }

    .comment-container {
      textarea {
        color: white;
        outline: none;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        border: 1px solid white;
        height: 150px;
        resize: none;
      }
    }

    .ending-container {
      .message {
        color: #54ff82;
      }
    }
  }
}

.header {
  font-family: $font_barlow;
  font-weight: 600;
  font-size: 2rem;
}

.paragraph {
  font-family: $font_barlow;
  font-weight: 300;
}

.hint-wrapper {
  color: white;
  font-style: italic;
  font-size: 0.9rem;
}

.hint-wrapper-mobile {
  top: 30%;
  color: white;
  font-style: italic;
  font-size: 0.9rem;

  .ml-1 {
    font-size: 1.1rem;
  }
}

.author-old-container {
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #131827, $alpha: 0.9);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .ytb-iframe-wrapper {
    iframe {
      height: 70vh;
    }
  }
}

.flythrough-container {
  @extend .author-old-container;
}

.flythrough-container-mobile {
  @extend .author-old-container;

  .container {
    height: 100%;
    margin-top: 5px;

    .flythrough-vid-mobile {
      height: 50%;
    }
  }
}

.book-launch-container {
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #131827, $alpha: 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ack-schools-container {
  @extend .book-launch-container;
}

.ack-orgs-container {
  @extend .ack-schools-container;
}

.contact-container {
  @extend.book-launch-container;

  .media-wrapper {
    i {
      width: 50px;
    }
    .paragraph {
      width: 160px;
    }
  }

  a {
    color: white;

    &:hover {
      color: $theme-color;
    }
  }
}

.carousel-root {
  .image-wrapper {
    .text-wrapper {
      position: absolute;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(170, 175, 189, 0.38) 38%,
        rgba(32, 44, 81, 1) 100%
      );
      text-align: start;
      transition: opacity 0.3s;
      opacity: 1;

      // &:hover {
      //   opacity: 1;
      // }
    }
  }
}

.olive-branch-container {
  @extend .book-launch-container;
}

.causes-container {
  @extend .book-launch-container;
}

.col-0 {
  flex: 0 0 0;
  max-width: 0;
}

// desktop map container
.map-container {
  position: absolute;
  width: 90%;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  @extend .unclickable;

  .toggle {
    color: white;
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    font-size: 0.8rem;
    font-family: $font_barlow;
    font-weight: 400;
    padding: 15px 10px 15px 10px;
    background-color: #202c51;
    opacity: 0.3;
    transition: opacity 0.3s;
  }

  .toggle.active {
    opacity: 1;
  }

  .toggle:hover {
    opacity: 1;
  }

  .body {
    width: 100%;
    background-color: rgba($color: #151c34, $alpha: 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .map-wrapper {
      width: 80%;
      position: relative;

      .hotspot-wrapper {
        position: absolute;
        width: 4%;
        @extend .clickable;

        &__main {
          width: 5%;
        }

        .hotspot {
          position: relative;
          width: 100%;
          transition: transform 0.3s;
          &:hover {
            transform: scale(1.2, 1.2);
          }
        }

        .text-wrapper {
          color: white;
          position: absolute;
          top: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 0.6rem;
          text-transform: uppercase;
          font-family: $font_barlow;
          font-weight: bold;
          left: 50%;
          transform: translateX(-50%);

          span {
            text-align: center;
          }
        }
      }

      .intro {
        left: 17%;
        top: 42%;
      }

      .who {
        left: 11%;
        top: 30%;
      }

      .what {
        left: 18%;
        top: 26%;
      }

      .legal-ed {
        left: 36%;
        top: 26%;
      }

      .superhero {
        left: 31%;
        top: 17%;
      }

      .library {
        left: 37%;
        top: 14%;
      }

      .cmsv {
        bottom: 37%;
        left: 37%;
      }

      .art {
        bottom: 45%;
        left: 43%;
      }

      .help {
        bottom: 48%;
        left: 48%;
      }

      .wicare {
        bottom: 47%;
        left: 55%;
      }

      .ed {
        top: 23%;
        right: 35%;
      }

      .me {
        top: 15%;
        right: 29%;
      }

      .ed-value {
        top: 26%;
        right: 25%;
      }

      .caregiver {
        bottom: 42%;
        right: 23%;
      }

      .TCM {
        bottom: 52%;
        right: 17%;
      }
      .dementia {
        right: 13%;
        bottom: 43%;
      }
    }
  }

  .toggle-target {
    transition: transform 0.8s;
    transform: translateX(0);
    pointer-events: initial;
  }

  .toggle-target.hidden {
    transform: translateX(91.666667%);
  }
}

// mobile map container
.map-container-mobile {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 25px;
  display: block;
  align-items: center;
  @extend .unclickable;

  .toggle {
    color: white;
    // border-top-left-radius: 10px;
    // border-bottom-left-radius: 10px;
    font-size: 0.8rem;
    font-family: $font_barlow;
    font-weight: 400;
    padding: 15px 10px 15px 10px;
    background-color: #202c51;
    opacity: 0.3;
    transition: opacity 0.3s;
  }

  .toggle.active {
    opacity: 1;
  }

  .toggle:hover {
    opacity: 1;
  }

  .body {
    padding: 3px;
    height: 98%;
    background-color: rgba($color: #151c34, $alpha: 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .control-hint-map-mobile {
      left: 4%;
    }

    .map-wrapper {
      width: 90%;
      position: relative;

      .hotspot-wrapper {
        position: absolute;
        width: 6.2%;
        @extend .clickable;

        &__main {
          width: 7.5%;
        }

        .hotspot {
          position: relative;
          width: 100%;
          transition: transform 0.3s;
        }

        .text-wrapper {
          color: white;
          width: 110px;
          position: absolute;
          top: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: left;
          font-size: 0.6rem;
          text-transform: uppercase;
          font-family: $font_barlow;
          font-weight: bold;
          left: 50%;
          transform: translateX(-50%);

          span {
            font-size: 0.8rem;
            text-align: center;
          }
        }
      }

      .intro {
        left: 14%;
        top: 50%;
      }

      .who {
        left: 7%;
        top: 38%;
      }

      .what {
        left: 15%;
        top: 30%;
      }

      .legal-ed {
        left: 33%;
        top: 26%;
      }

      .superhero {
        left: 26%;
        top: 17%;
      }

      .library {
        left: 33%;
        top: 10%;
      }

      .cmsv {
        bottom: 15%;
        left: 32%;
      }

      .art {
        bottom: 28%;
        left: 38%;
      }

      .help {
        bottom: 35%;
        left: 47%;
      }

      .wicare {
        bottom: 20%;
        left: 52%;
      }

      .ed {
        top: 30%;
        right: 33%;
      }

      .me {
        top: 12%;
        right: 30%;
      }

      .ed-value {
        top: 24%;
        right: 23%;
      }

      .caregiver {
        bottom: 13%;
        right: 23%;
      }

      .TCM {
        bottom: 33%;
        right: 18%;
      }
      .dementia {
        right: 12%;
        bottom: 18%;
      }
    }
  }

  .toggle-target {
    transition: transform 0.8s;
    transform: translateX(0);
    pointer-events: initial;
  }

  .toggle-target.hidden {
    transform: translateX(91.666667%);
  }
}

.ending-container {
  @extend .book-launch-container;
  background-color: rgba($color: #131827, $alpha: 0.9);

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.ending-container-mobile {
  @extend .book-launch-container;
  background-color: rgba($color: #131827, $alpha: 0.9);
  width: 100vw;
  height: 100vh;

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.tutorial-btn-wrapper {
  @extend .clickable;
  position: absolute;

  bottom: 23px;
  left: 105px;
  opacity: 0;
  transition: opacity 0.3s;

  i {
    font-size: 24px;
    font-weight: 200;
    color: white;
    transition: text-shadow 0.3s;
    &:hover {
      color: #ff3158;
    }
    &.glow {
      text-shadow: 0px 0px 7px white;
    }
  }

  &.show {
    opacity: 1;
  }
}

.tutorial-btn-wrapper-mobile {
  @extend .clickable;
  position: absolute;

  bottom: 23px;
  left: 105px;
  opacity: 0;
  transition: opacity 0.3s;

  i {
    font-size: 24px;
    font-weight: 200;
    color: white;
    transition: text-shadow 0.3s;
    &:hover {
      color: #ff3158;
    }
    &.glow {
      text-shadow: 0px 0px 7px white;
    }
  }

  &.show {
    opacity: 1;
  }
}

.tutorial-container {
  position: absolute;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #131827, $alpha: 0.95);

  img.screenshot {
    width: 100vw;
    height: 100vh;
    object-position: center;

    object-fit: contain;

    @media (orientation: portrait) {
      object-fit: contain;
    }
    @media (orientation: landscape) {
      object-fit: fill;
    }
  }

  button.return {
    position: absolute;
    transform: translateX(-50%);

    bottom: 30px;

    @media (orientation: portrait) {
      left: 50%;
    }
    @media (orientation: landscape) {
      left: 60%;
    }
  }
}

.share-button {
  background: white;
}
