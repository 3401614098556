$theme-color: #f1b148;
$popup-color: #202c51;
$text-color: white;
$font_merriweather: "Merriweather", serif;
$font_barlow: "Barlow", serif;
$font_montserrat: "Montserrat", serif;

$btn_font_size: 12px;

@mixin transition-ease($property, $time: 3s) {
  transition: #{$property} $time ease-in-out;
}

@mixin top-left {
  top: 0;
  left: 0;
}

@mixin top-right($top: 5%, $right: 2%) {
  top: $top;
  right: $right;
  position: absolute;
}

@mixin bottom-right($bottom: 5%, $right: 2%) {
  bottom: $bottom;
  right: $right;
  position: absolute;
}

// @mixin bottom-align($bottom: 5%) {
//   bottom: $bottom;
//   left: 56%;
//   position: absolute;
// }

@mixin opacity-0 {
  opacity: 0;
}

@mixin bg-black($zIndex: 0) {
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: $zIndex;
}

@mixin full-screen {
  width: 100vw;
  height: 100vh;
}

@mixin responsive-btn {
  transform: scale(1, 1);

  &:hover {
    transform: scale(1.3, 1.3);
    cursor: pointer;
  }
  &:focus {
    transform: scale(1.1, 1.1);
    cursor: pointer;
  }
}

@mixin responsive-shadow {
  &:hover {
    box-shadow: 2px 3px 2px 1px rgb(37, 37, 37);
  }
}

@mixin fade-in($duration) {
  animation: fadeIn $duration linear;
  animation-fill-mode: forwards;
}

@mixin media-sm {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin media-md-sm {
  @media screen and (min-width: 700px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 780px) {
    @content;
  }
}
@mixin media-lg {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
@mixin media-xl {
  @media screen and (min-width: 1600px) {
    @content;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
